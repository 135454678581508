/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SectionType } from '../../data/puzzles/puzzle_defs';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import PointSectionHeader from './PointSectionHeader';
import PuzzleTable from './PuzzleTable/PuzzleTable';
import { AllPuzzleStats } from '../../utils/puzzle_stats';

const CSS_PUZZLE_SECTIONS = css({
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
});

interface PuzzleSectionsProps {
    sections: SectionType[];
    isArchived?: boolean;
    /**
     * Puzzle stats regarding number of guesses and solves for each puzzle.
     */
    allPuzzleStats?: AllPuzzleStats;
    /**
     * List of completed puzzle names.
     */
    completedPuzzles?: string[];
}

function PuzzleSections(props: PuzzleSectionsProps) {
    const { sections, isArchived, allPuzzleStats, completedPuzzles } = props;
    return (
        <div css={CSS_PUZZLE_SECTIONS}>
            {sections.map((section) => {
                return (
                    <div key={section.name} css={CSS_IGNORE_TOP_CONTENT_PADDING}>
                        {section.puzzles.find((puzzleInfo) => !!puzzleInfo.link) !== undefined && (
                            <>
                                <PointSectionHeader
                                    sectionName={section.name}
                                    points={section.points}
                                    isSingle={section.puzzles.length === 1}
                                />
                                <PuzzleTable
                                    puzzles={section.puzzles}
                                    isArchived={isArchived}
                                    allPuzzleStats={allPuzzleStats}
                                    completedPuzzles={completedPuzzles}
                                />
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default PuzzleSections;
