/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import { AuthContext } from '../../..';
import Row from '../../../components/Form/Row';
import TextField from '../../../components/TextField/TextField';
import ActionButton, { ButtonState } from '../../../components/ActionButton/ActionButton';
import { TEAM_NAME_MAX_LENGTH } from '../account_page_utils';
import { FunctionsError } from 'firebase/functions';
import { updateProfile } from 'firebase/auth';
import { css, useTheme } from '@emotion/react';
import Form from '../../../components/Form/Form';
import { useFirebaseFunction } from '../../../hooks/useFirebaseFunction';

function ChangeTeamName() {
    const [successMessage, setSuccessMessage] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamNameErrorMessage, setTeamNameErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const auth = useContext(AuthContext);
    const theme = useTheme();

    const cssSuccessMessage = css({
        color: theme.colors.text.success,
    });

    const checkErrors = () => {
        let hasError = false;
        if (!teamName) {
            setTeamNameErrorMessage('Please enter a team name.');
            hasError = true;
        }
        if (!password) {
            setPasswordErrorMessage('Please enter a password.');
            hasError = true;
        }
        if (teamNameErrorMessage || passwordErrorMessage) {
            hasError = true;
        }
        return hasError;
    };

    const { callFunction: updateTeamNameFn, isRequestInProgress } =
        useFirebaseFunction('updateTeamName');

    const updateTeamName = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgress) {
            try {
                if (auth.currentUser) {
                    await updateTeamNameFn({
                        username: auth.currentUser.uid,
                        teamName: teamName,
                        password: password,
                    });
                    updateProfile(auth.currentUser, {
                        displayName: teamName,
                    });
                    setSuccessMessage('Team name updated!');
                    setTeamName('');
                    setPassword('');
                }
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/permission-denied') {
                    setPasswordErrorMessage((error as FunctionsError).message);
                } else if ((error as FunctionsError).code === 'functions/already-exists') {
                    setTeamNameErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while changing team name:', error);
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgress;

    return (
        <Form onSubmit={updateTeamName} isDisabled={isButtonDisabled}>
            <Row>
                <TextField
                    value={teamName}
                    setValue={(value) => {
                        setTeamName(value);
                        if (value.length > TEAM_NAME_MAX_LENGTH) {
                            setTeamNameErrorMessage(
                                `Please limit your team name to ${TEAM_NAME_MAX_LENGTH} characters or less.`,
                            );
                        } else {
                            setTeamNameErrorMessage('');
                        }
                        setSuccessMessage('');
                    }}
                    label="New Team Name"
                    description="Team display name, which will be shown on public leaderboard."
                    errorMessage={teamNameErrorMessage}
                />
            </Row>
            <Row>
                <TextField
                    value={password}
                    setValue={(value) => {
                        setPassword(value);
                        setPasswordErrorMessage('');
                        setSuccessMessage('');
                    }}
                    label="Password"
                    isPassword
                    errorMessage={passwordErrorMessage}
                />
            </Row>
            {successMessage && (
                <Row>
                    <div css={cssSuccessMessage}>{successMessage}</div>
                </Row>
            )}
            <ActionButton
                // Hard-coding width so that the width of button stays the same when disabled.
                width="190px"
                buttonStateOverride={isButtonDisabled ? ButtonState.DISABLED : ButtonState.DEFAULT}
                isFormSubmit={!isButtonDisabled}
            >
                {isRequestInProgress ? 'Changing' : 'Change Team Name'}
            </ActionButton>
        </Form>
    );
}

export default ChangeTeamName;
