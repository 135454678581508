/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const CSS_ROW_ITEM = css({
    display: 'flex',
    width: '100%',
});

interface RowItemProps {
    children: React.ReactNode;
}

/**
 * This component should be wrapped by the Row component.
 * This will adjust the spacing and width of all RowItems in a Row to be even.
 */
function RowItem(props: RowItemProps) {
    const { children } = props;
    return <div css={CSS_ROW_ITEM}>{children}</div>;
}

export default RowItem;
