/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ActionButton from '../../components/ActionButton/ActionButton';
import PageLayout from '../../components/PageLayout/PageLayout';
import citrusWreath from '../../assets/images/citrus_wreath_with_puzzle.png';
import {
    CURRENT_YEAR,
    DISCORD_URL,
    PUZZLEHUNT_DATES,
    PUZZLEHUNT_NAME,
} from '../../data/puzzlehunt_details';

const CSS_HOME_PAGE_LAYOUT = css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

const CSS_HOME_PAGE_IMAGE = css({ width: '300px' });

const CSS_BUTTONS = css({
    display: 'flex',
    gap: '8px',
});

function HomePage() {
    return (
        <PageLayout>
            <div css={CSS_HOME_PAGE_LAYOUT}>
                <img
                    src={citrusWreath}
                    alt="Citrus wreath encircling a jigsaw puzzle piece"
                    css={CSS_HOME_PAGE_IMAGE}
                />
                <h1>
                    {PUZZLEHUNT_NAME} {CURRENT_YEAR}: {PUZZLEHUNT_DATES}
                </h1>
                <div>Welcome to the vast world of puzzle hunting and holidays galore...</div>
                <div css={CSS_BUTTONS}>
                    <ActionButton link={'/register'}>Register</ActionButton>
                    <ActionButton link={DISCORD_URL}>Join the Discord</ActionButton>
                </div>
            </div>
            {/* <GoogleLogin
                clientId="912336522432-kimbqmjepd3d5iiqe5dbc30epv3kgbs8.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={(response): void => {
                    console.log(response);
                }}
            /> */}
        </PageLayout>
    );
}

export default HomePage;
