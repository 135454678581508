import { PuzzleInfo } from '../../../data/puzzles/puzzle_defs';
import { AllPuzzleStats } from '../../../utils/puzzle_stats';
import PuzzleRow from './PuzzleRow';

interface PuzzleTableProps {
    puzzles: PuzzleInfo[];
    isArchived?: boolean;
    /**
     * Puzzle stats regarding number of guesses and solves for each puzzle.
     */
    allPuzzleStats?: AllPuzzleStats;
    /**
     * List of completed puzzle names.
     */
    completedPuzzles?: string[];
}

function PuzzleTable(props: PuzzleTableProps) {
    const { puzzles, isArchived, allPuzzleStats, completedPuzzles } = props;

    return (
        <div>
            {puzzles.map((puzzleInfo) => {
                return (
                    puzzleInfo.link && (
                        <PuzzleRow
                            key={puzzleInfo.name}
                            puzzleInfo={puzzleInfo}
                            puzzleStats={
                                // If there are any stats in this table, populate all stats even if they are empty.
                                allPuzzleStats
                                    ? puzzleInfo.name in allPuzzleStats
                                        ? allPuzzleStats[puzzleInfo.name]
                                        : { guesses: 0, solves: 0 }
                                    : undefined
                            }
                            isArchived={isArchived}
                            isCompleted={(completedPuzzles ?? []).includes(puzzleInfo.name)}
                        />
                    )
                );
            })}
        </div>
    );
}

export default PuzzleTable;
