/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import TableOfContents, { TableOfContentsType } from './TableOfContents';
import {
    ScreenSize,
    isSmallerThanOrEqual,
    useScreenSizeBreakpoints,
} from '../../hooks/useScreenSizeBreakpoints';

interface PageWithTableOfContentsProps {
    children: React.ReactNode;
    contents: TableOfContentsType;
    /**
     * The text of the selected "table of contents" item.
     */
    selectedItem: string;
}

/**
 * Wrapper around the contents of a page which includes a "table of contents"
 * sidebar that can be used to toggle between page content.
 */
function PageWithTableOfContents(props: PageWithTableOfContentsProps) {
    const { children, contents, selectedItem } = props;
    const screenSize = useScreenSizeBreakpoints();

    const hasVerticalLayout = isSmallerThanOrEqual(screenSize, ScreenSize.SMALL);
    const cssPageWithTableOfContents = css({
        alignItems: hasVerticalLayout ? 'center' : 'flex-start',
        display: 'flex',
        flexDirection: hasVerticalLayout ? 'column' : 'row',
        gap: '32px',
    });

    return (
        <div css={cssPageWithTableOfContents}>
            <TableOfContents contents={contents} selectedItem={selectedItem} />
            {children}
        </div>
    );
}

export default PageWithTableOfContents;
