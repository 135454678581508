/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { PUZZLE_HIGHLIGHTS } from '../../../data/puzzles/highlights';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../../utils/content_page_css';
import PuzzleTable from '../../../components/PuzzleDisplay/PuzzleTable/PuzzleTable';

const CSS_HIGHLIGHTS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

function HighlightsContent() {
    const theme = useTheme();

    return (
        <div css={CSS_IGNORE_TOP_CONTENT_PADDING}>
            <p>Some of our favorite puzzles over the years...</p>
            <div css={CSS_HIGHLIGHTS}>
                {Object.entries(PUZZLE_HIGHLIGHTS)
                    .sort((a, b) => {
                        // Sort in reverse chronological order.
                        return a[0] > b[0] ? -1 : 1;
                    })
                    .map(([year, highlights]) => {
                        return (
                            <div key={year}>
                                <h2 style={{ color: theme.colors.text.dark }}>{year}</h2>
                                <PuzzleTable puzzles={highlights} isArchived />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default HighlightsContent;
