/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
    ScreenSize,
    isSmallerThanOrEqual,
    useScreenSizeBreakpoints,
} from '../../hooks/useScreenSizeBreakpoints';
import { FOUNDERS, PUZZLE_WRITERS, TEST_SOLVERS } from '../../data/team_members';
import { TeamMemberIntro, TestSolverIntro } from './TeamIntroduction';

const CSS_EXTRA_PADDING = css({ padding: '0px 0px 20px 0px' });

const CSS_TEAM_MEMBERS = css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    paddingBottom: '30px',
});

interface RoleSectionProps {
    children: React.ReactNode;
    isCentered: boolean;
}

function RoleSection(props: RoleSectionProps) {
    const { children, isCentered } = props;
    return (
        <div
            css={CSS_TEAM_MEMBERS}
            style={{ justifyContent: isCentered ? 'center' : 'flex-start' }}
        >
            {children}
        </div>
    );
}

function MeetTheTeamContent() {
    const screenSize = useScreenSizeBreakpoints();
    const isCentered = isSmallerThanOrEqual(screenSize, ScreenSize.MEDIUM);
    return (
        <div
            style={
                isCentered
                    ? {
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                      }
                    : undefined
            }
        >
            <h3>Co-founders</h3>
            <RoleSection isCentered={isCentered}>
                {FOUNDERS.map((memberInfo) => {
                    return <TeamMemberIntro key={memberInfo.name} {...memberInfo} />;
                })}
            </RoleSection>
            <h3>Puzzle writers</h3>
            <RoleSection isCentered={isCentered}>
                {PUZZLE_WRITERS.map((memberInfo) => {
                    return <TeamMemberIntro key={memberInfo.name} {...memberInfo} />;
                })}
            </RoleSection>
            <h3>Testsolvers</h3>
            <div css={CSS_EXTRA_PADDING}>
                Plus, the team wouldn't be complete without our amazing testsolvers!
            </div>
            <RoleSection isCentered={isCentered}>
                {TEST_SOLVERS.map((solverInfo) => {
                    return <TestSolverIntro key={solverInfo.name} {...solverInfo} />;
                })}
            </RoleSection>
        </div>
    );
}

export default MeetTheTeamContent;
