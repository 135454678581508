import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import { SectionType, Sections } from '../../data/puzzles/puzzle_defs';
import PuzzleSections from '../../components/PuzzleDisplay/PuzzleSections';
import { useCurrentPuzzleSections } from '../../hooks/useCurrentPuzzleSections';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from '../../hooks/useAuthUser';
import { AllPuzzleStats } from '../../utils/puzzle_stats';
import { isMetaAcccessible } from '../../utils/flagging';

function Puzzles() {
    const [completedPuzzleNames, setCompletedPuzzleNames] = useState<string[]>();
    const [allPuzzleStats, setAllPuzzleStats] = useState<AllPuzzleStats>();
    const authUser = useAuthUser();
    const puzzleSections = useCurrentPuzzleSections();

    const {
        callFunction: getSolvedPuzzlesFn,
        isRequestInProgress: isGetSolvedPuzzlesRequestInProgress,
    } = useFirebaseFunction('getSolvedPuzzles');
    const {
        callFunction: getPuzzleStatsFn,
        isRequestInProgress: isGetPuzzleStatsRequestInProgress,
    } = useFirebaseFunction('getPuzzleStats');

    const loadSolvedAnswers = useCallback(
        (username: string) => {
            if (!isGetSolvedPuzzlesRequestInProgress && completedPuzzleNames === undefined) {
                getSolvedPuzzlesFn({
                    username: username,
                })
                    .then((getSolvedPuzzlesResponse) => {
                        setCompletedPuzzleNames(
                            Object.keys(getSolvedPuzzlesResponse.data.solvedPuzzles),
                        );
                    })
                    .catch((error) => {
                        console.error('Error while loading solved puzzles:', error);
                    });
            }
        },
        [completedPuzzleNames, getSolvedPuzzlesFn, isGetSolvedPuzzlesRequestInProgress],
    );

    const loadPuzzleStats = useCallback(
        (username: string) => {
            if (!isGetPuzzleStatsRequestInProgress && allPuzzleStats === undefined) {
                getPuzzleStatsFn({
                    username: username,
                })
                    .then((getPuzzleStatsResponse) => {
                        setAllPuzzleStats(getPuzzleStatsResponse.data.puzzleStats);
                    })
                    .catch((error) => {
                        console.error('Error while loading solved puzzles:', error);
                    });
            }
        },
        [allPuzzleStats, getPuzzleStatsFn, isGetPuzzleStatsRequestInProgress],
    );

    useEffect(() => {
        if (authUser?.uid) {
            loadSolvedAnswers(authUser.uid);
            loadPuzzleStats(authUser.uid);
        }
    }, [authUser, loadPuzzleStats, loadSolvedAnswers]);

    let relevantSections = [Sections.EASY, Sections.MEDIUM, Sections.HARD];
    if (puzzleSections && Sections.META in puzzleSections && isMetaAcccessible(authUser)) {
        relevantSections = [...relevantSections, Sections.META];
    }

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.PUZZLES}</Title>
            {puzzleSections && (
                <PuzzleSections
                    sections={relevantSections.map((relevantSection) => {
                        const processedSections = {
                            ...(puzzleSections[relevantSection] as SectionType),
                        };
                        return processedSections;
                    })}
                    allPuzzleStats={allPuzzleStats}
                    completedPuzzles={completedPuzzleNames}
                />
            )}
        </PageLayout>
    );
}

export default Puzzles;
