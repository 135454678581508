/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

interface PointSectionHeaderProps {
    sectionName: string;
    points: number;
    // Whether there is a single puzzle in this section.
    // This is to determine the plurality of the points text.
    isSingle?: boolean;
}

const CSS_POINT_SECTION_HEADER = css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
});

const CSS_SECTION_NAME = css({
    // Override the header margin in order to apply margin on the parent.
    // This allows us to center the section name with the points text properly
    // since flexbox doesn't work well with margins.
    margin: '0px',
});

function PointSectionHeader(props: PointSectionHeaderProps) {
    const { sectionName, points, isSingle } = props;
    const theme = useTheme();

    return (
        <div css={CSS_POINT_SECTION_HEADER}>
            <h2
                css={CSS_SECTION_NAME}
                style={{
                    color: theme.colors.text.dark,
                }}
            >
                {sectionName}
            </h2>
            ({points} points{isSingle ? '' : ' each'})
        </div>
    );
}

export default PointSectionHeader;
