/** @jsxImportSource @emotion/react */
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';

function Hints() {
    return (
        <PageLayout>
            <Title>{PAGE_NAMES.HINTS}</Title>
            Sorry, this feature has not been implemented yet.
        </PageLayout>
    );
}

export default Hints;
