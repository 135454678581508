/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Sections, WrapUpType } from '../../data/puzzles/puzzle_defs';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import ActionButton from '../ActionButton/ActionButton';

const CSS_WRAP_UP_OPTIONS = css({
    display: 'flex',
    gap: '8px',
});

interface WrapUpSectionProps {
    wrapUpInfo: WrapUpType;
}

function WrapUpSection(props: WrapUpSectionProps) {
    const { wrapUpInfo } = props;
    const theme = useTheme();

    return (
        <div css={CSS_IGNORE_TOP_CONTENT_PADDING}>
            <h2 style={{ color: theme.colors.text.dark }}>{Sections.WRAP_UP}</h2>
            <div css={CSS_WRAP_UP_OPTIONS}>
                <ActionButton link={wrapUpInfo.solutions}>Wrap-up + All Solutions</ActionButton>
                <ActionButton link={wrapUpInfo.leaderboard}>Leaderboard</ActionButton>
            </div>
        </div>
    );
}

export default WrapUpSection;
