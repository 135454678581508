/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import { isRelativeUrl } from '../../utils/url';
import { forwardRef } from 'react';

const CSS_LINK = css({
    width: 'fit-content',
});

const CSS_TEXT_UNDERLINE = css({
    textDecoration: 'none',
});

interface ConditionalLinkProps {
    children: React.ReactNode;
    /**
     * Relative links (starting with "/") will show in the current tab without reloading the entire page.
     * Absolute links will open in a new tab.
     */
    link: string | undefined;
    className?: string;
    onClick?: () => void;
    showUnderline?: boolean;
    ariaLabel?: string;
    tabIndex?: number;
}

const ConditionalLink = forwardRef<HTMLAnchorElement | HTMLDivElement, ConditionalLinkProps>(
    function ConditionalLink(props, ref) {
        const { children, link, className, onClick, showUnderline, ariaLabel, tabIndex } = props;

        const cssConditionalLink = showUnderline ? null : CSS_TEXT_UNDERLINE;

        if (link) {
            if (isRelativeUrl(link)) {
                return (
                    <Link
                        to={link}
                        className={className}
                        css={[CSS_LINK, cssConditionalLink]}
                        onClick={onClick}
                        aria-label={ariaLabel}
                        tabIndex={tabIndex}
                        ref={ref as React.RefObject<HTMLAnchorElement>}
                    >
                        {children}
                    </Link>
                );
            }
            return (
                <Link
                    to={link}
                    className={className}
                    css={cssConditionalLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={onClick}
                    aria-label={ariaLabel ?? `${children} (opens in a new tab)`}
                    tabIndex={tabIndex}
                    ref={ref as React.RefObject<HTMLAnchorElement>}
                >
                    {children}
                </Link>
            );
        }
        return (
            <div
                className={className}
                onClick={onClick}
                aria-label={ariaLabel}
                ref={ref as React.RefObject<HTMLDivElement>}
            >
                {children}
            </div>
        );
    },
);

export default ConditionalLink;
