/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import { AuthContext } from '../../..';
import Row from '../../../components/Form/Row';
import TextField from '../../../components/TextField/TextField';
import ActionButton, { ButtonState } from '../../../components/ActionButton/ActionButton';
import { PASSWORD_MAX_LENGTH } from '../account_page_utils';
import { FunctionsError } from 'firebase/functions';
import { css, useTheme } from '@emotion/react';
import Form from '../../../components/Form/Form';
import { useFirebaseFunction } from '../../../hooks/useFirebaseFunction';

function ChangePassword() {
    const [successMessage, setSuccessMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('');
    const auth = useContext(AuthContext);
    const theme = useTheme();

    const cssSuccessMessage = css({
        color: theme.colors.text.success,
    });

    const checkErrors = () => {
        let hasError = false;
        if (!newPassword) {
            setNewPasswordErrorMessage('Please enter a password.');
            hasError = true;
        }
        if (!confirmedPassword) {
            setConfirmedPasswordErrorMessage('Please re-enter your password.');
            hasError = true;
        } else if (newPassword !== confirmedPassword) {
            setConfirmedPasswordErrorMessage("Passwords don't match.");
            hasError = true;
        }
        if (!oldPassword) {
            setOldPasswordErrorMessage('Please enter your previous password.');
            hasError = true;
        }
        if (newPasswordErrorMessage || confirmedPasswordErrorMessage || oldPasswordErrorMessage) {
            hasError = true;
        }
        return hasError;
    };

    const { callFunction: updatePasswordFn, isRequestInProgress } =
        useFirebaseFunction('updatePassword');

    const updatePassword = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgress) {
            try {
                if (auth.currentUser) {
                    await updatePasswordFn({
                        username: auth.currentUser.uid,
                        newPassword: newPassword,
                        oldPassword: oldPassword,
                    });
                    setSuccessMessage('Password updated!');
                    setNewPassword('');
                    setConfirmedPassword('');
                    setOldPassword('');
                }
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/permission-denied') {
                    setOldPasswordErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while changing password:', error);
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgress;

    return (
        <Form onSubmit={updatePassword} isDisabled={isButtonDisabled}>
            <Row>
                <TextField
                    value={newPassword}
                    setValue={(value) => {
                        setNewPassword(value);
                        if (value.length > PASSWORD_MAX_LENGTH) {
                            setNewPasswordErrorMessage(
                                `Please limit your password to ${PASSWORD_MAX_LENGTH} characters or less.`,
                            );
                        } else {
                            setNewPasswordErrorMessage('');
                        }
                        setSuccessMessage('');
                    }}
                    label="New Team Password"
                    description="Please share this password with your team, and only your team."
                    isPassword
                    errorMessage={newPasswordErrorMessage}
                />
            </Row>
            <Row>
                <TextField
                    value={confirmedPassword}
                    setValue={(value) => {
                        setConfirmedPassword(value);
                        setConfirmedPasswordErrorMessage('');
                        setSuccessMessage('');
                    }}
                    label="Confirm Password"
                    isPassword
                    errorMessage={confirmedPasswordErrorMessage}
                />
            </Row>
            <Row>
                <TextField
                    value={oldPassword}
                    setValue={(value) => {
                        setOldPassword(value);
                        setOldPasswordErrorMessage('');
                    }}
                    label="Old Password"
                    isPassword
                    errorMessage={oldPasswordErrorMessage}
                />
            </Row>
            {successMessage && (
                <Row>
                    <div css={cssSuccessMessage}>{successMessage}</div>
                </Row>
            )}
            <ActionButton
                // Hard-coding width so that the width of button stays the same when disabled.
                width="170px"
                buttonStateOverride={
                    isRequestInProgress ? ButtonState.DISABLED : ButtonState.DEFAULT
                }
                isFormSubmit={!isButtonDisabled}
            >
                {isRequestInProgress ? 'Changing' : 'Change Password'}
            </ActionButton>
        </Form>
    );
}

export default ChangePassword;
