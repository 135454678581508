/** @jsxImportSource @emotion/react */
import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { css, Global, ThemeProvider } from '@emotion/react';

import {
    ARCHIVED_HUNT_NAMES,
    PAGE_NAMES,
    PAGE_TO_URL,
    PUZZLE_PAGE_PREPEND_URL,
} from './data/page_structure';
import AboutUs from './pages/AboutUs/AboutUs';
import AccountPage from './pages/ManageAccountPages/AccountPage/AccountPage';
import Announcements from './pages/Announcements/Announcements';
import ArchivedHunts from './pages/Archive/ArchivedHunts/ArchivedHunts';
import ErrorPage from './pages/ErrorPage';
import ForgotPasswordPage from './pages/ManageAccountPages/ForgotPasswordPage';
import Highlights from './pages/Archive/Highlights/Highlights';
import HomePage from './pages/HomePage/HomePage';
import Puzzles from './pages/Puzzles/Puzzles';
import RulesAndInfo from './pages/RulesAndInfo/RulesAndInfo';
import LoginPage from './pages/ManageAccountPages/LoginPage';
import RegisterPage from './pages/ManageAccountPages/RegisterPage';
import Teams from './pages/Teams/Teams';
import { THEME } from './theme';
import './index.css';

import { FirebaseApp, initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, Functions, getFunctions } from 'firebase/functions';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import IndividualPuzzlePage from './pages/Puzzles/IndividualPuzzlePage';
import FlaggedPage from './pages/FlaggedPage';
import Archive from './pages/Archive/Archive';
import Hints from './pages/Hints/Hints';
import Story from './pages/Story/Story';

const CSS_GLOBAL_TEXT_STYLES = css({
    body: {
        backgroundColor: 'white',
        color: THEME.colors.text.dark,
        margin: 0,
        fontFamily:
            "'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontSize: '18px',
    },
    code: {
        fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
    h1: {
        fontFamily: 'Oswald',
        fontSize: '30px',
        fontWeight: 300,
        margin: 0,
    },
    h2: {
        color: THEME.colors.primary,
        fontSize: '22px',
    },
    h3: {
        margin: '0px',
        marginBottom: '4px',
        fontSize: '18px',
    },
    ul: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    a: {
        color: THEME.colors.text.dark,
        '&:hover': {
            color: THEME.colors.primary,
        },
    },
    b: {
        fontWeight: 600,
    },
    [`@media (max-width: ${THEME.breakpoints.medium})`]: {
        b: {
            fontWeight: 550,
        },
        h2: {
            fontWeight: 550,
        },
        h3: {
            fontWeight: 550,
        },
        h4: {
            fontWeight: 550,
        },
    },
    '::selection': {
        backgroundColor: THEME.colors.background.selection,
    },
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.ABOUT_US],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.ABOUT_US}>
                <AboutUs />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.ACCOUNT],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.ACCOUNT}>
                <AccountPage />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.ANNOUNCEMENTS],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.ANNOUNCEMENTS}>
                <Announcements />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.ARCHIVE],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.ARCHIVE}>
                <Archive />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.FORGOT_PASSWORD],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.FORGOT_PASSWORD}>
                <ForgotPasswordPage />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.HIGHLIGHTS],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.HIGHLIGHTS}>
                <Highlights />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.HINTS],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.HINTS} adminOnly>
                <Hints />
            </FlaggedPage>
        ),
    },
    {
        path: `/${PUZZLE_PAGE_PREPEND_URL}/:puzzleNameInUrl`,
        element: (
            <FlaggedPage pageName={PAGE_NAMES.PUZZLES}>
                <IndividualPuzzlePage />,
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.PUZZLES],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.PUZZLES}>
                <Puzzles />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.REGISTER],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.REGISTER}>
                <RegisterPage />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.RULES_AND_INFO],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.RULES_AND_INFO}>
                <RulesAndInfo />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.LOGIN],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.LOGIN}>
                <LoginPage />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.TEAMS],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.TEAMS} activePageOnly>
                <Teams />
            </FlaggedPage>
        ),
    },
    {
        // IMPORTANT: REMOVE THIS ONCE WE START SETTING UP 2025.
        path: PAGE_TO_URL[PAGE_NAMES.STORY],
        element: (
            <FlaggedPage pageName={PAGE_NAMES.STORY} activePageOnly>
                <Story />
            </FlaggedPage>
        ),
    },
    {
        path: PAGE_TO_URL[PAGE_NAMES.ADMIN],
        element: <LoginPage isAdmin />,
    },
    ...ARCHIVED_HUNT_NAMES.map((huntName) => {
        return {
            path: PAGE_TO_URL[huntName],
            element: <ArchivedHunts />,
            errorElement: (
                <FlaggedPage pageName={PAGE_NAMES.ARCHIVE}>
                    <ErrorPage />
                </FlaggedPage>
            ),
        };
    }),
]);

const firebaseConfig = {
    apiKey: 'AIzaSyBpqDL9Y3n-LXCruGyXbQemr4zoOnvvY9k',
    authDomain: 'holiday-hoopla.firebaseapp.com',
    databaseURL: 'https://holiday-hoopla-default-rtdb.firebaseio.com',
    projectId: 'holiday-hoopla',
    storageBucket: 'holiday-hoopla.appspot.com',
    messagingSenderId: '912336522432',
    appId: '1:912336522432:web:dfb66f9bd7336cbfcd7125',
    measurementId: 'G-KL97Q9BXPJ',
};

const app = initializeApp(firebaseConfig);
// If unit testing, be sure to provide a mock value for app,
// or else we'll use the real firebase app.
export const FirebaseContext = createContext<FirebaseApp>(app);

const functions = getFunctions();
export const FunctionsContext = createContext<Functions>(functions);
const auth = getAuth();
export const AuthContext = createContext<Auth>(auth);

if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// NOTE: React strict mode causes all components to be rendered an extra time.
root.render(
    <React.StrictMode>
        <Global styles={CSS_GLOBAL_TEXT_STYLES} />
        <ThemeProvider theme={THEME}>
            <FirebaseContext.Provider value={app}>
                <AuthContext.Provider value={auth}>
                    <FunctionsContext.Provider value={functions}>
                        <RouterProvider router={router} />
                    </FunctionsContext.Provider>
                </AuthContext.Provider>
            </FirebaseContext.Provider>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
