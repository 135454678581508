import DOMPurify from 'dompurify';
import { Renderer, marked } from 'marked';
import { useEffect, useState } from 'react';

const renderer: Partial<Renderer> = {
    listitem: function ({ tokens }) {
        const text = this.parser?.parseInline(tokens);
        return `<li>${text}</li>`;
    },
};

marked.use({ renderer, gfm: true });

export const useMarkdown = (markdownFilePath: string) => {
    const [html, setHtml] = useState<string>();
    useEffect(() => {
        if (html === undefined) {
            const getMarkdownHtml = async () => {
                const response = await fetch(markdownFilePath);
                const markdownText = await response.text();
                const parsedText = await marked.parse(markdownText);
                setHtml(DOMPurify.sanitize(parsedText));
            };
            getMarkdownHtml();
        }
    }, [html, markdownFilePath]);

    return { html };
};
