/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import TextField from '../TextField/TextField';
import RowItem from '../Form/RowItem';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import {
    ScreenSize,
    isSmallerThanOrEqual,
    useScreenSizeBreakpoints,
} from '../../hooks/useScreenSizeBreakpoints';
import { TeamMembersType } from './edit_team_requirements';
import ActionButton, { ButtonType } from '../ActionButton/ActionButton';
import Row from '../Form/Row';

const CSS_REMOVE_BUTTON_WRAPPER = css({
    display: 'flex',
    // Hard-coded to match the width of the button containing the icon.
    // The value is derived by adding 24px icon size + 8px top padding + 8px bottom padding.
    minWidth: '40px',
});

const CSS_REMOVE_BUTTON = css({
    alignItems: 'flex-end',
    display: 'flex',
    position: 'absolute',
    // Hard-coded to line up the icon with the textfield.
    // This way, the icon does not change position when there are error messages.
    top: '24px',
});

const CSS_GROUP_VERTICAL_FIELDS = css({
    borderRadius: '4px',
    padding: '8px',
    marginBottom: '8px',
});

interface TeamMemberRowProps {
    rowIndex: number;
    teamMembers: TeamMembersType;
    setTeamMembers: (teamMembers: TeamMembersType) => void;
}

function NameTextField(props: TeamMemberRowProps) {
    const { rowIndex, teamMembers, setTeamMembers } = props;
    return (
        <TextField
            label="Name"
            value={teamMembers[rowIndex].name}
            setValue={(name) => {
                const updatedTeamMembers = [...teamMembers];
                updatedTeamMembers[rowIndex].name = name;
                updatedTeamMembers[rowIndex].nameErrorMessage = '';
                setTeamMembers(updatedTeamMembers);
            }}
            errorMessage={teamMembers[rowIndex].nameErrorMessage}
        />
    );
}

function EmailTextField(props: TeamMemberRowProps) {
    const { rowIndex, teamMembers, setTeamMembers } = props;
    return (
        <TextField
            label="Email"
            value={teamMembers[rowIndex].email}
            setValue={(email) => {
                const updatedTeamMembers = [...teamMembers];
                updatedTeamMembers[rowIndex].email = email;
                updatedTeamMembers[rowIndex].emailErrorMessage = '';
                setTeamMembers(updatedTeamMembers);
            }}
            errorMessage={teamMembers[rowIndex].emailErrorMessage}
        />
    );
}

function DeleteTeamMemberButton(props: TeamMemberRowProps) {
    const { rowIndex, teamMembers, setTeamMembers } = props;
    return (
        <div css={CSS_REMOVE_BUTTON_WRAPPER}>
            <div css={CSS_REMOVE_BUTTON}>
                <ActionButton
                    buttonType={ButtonType.INVISIBLE_ON_WHITE}
                    onClick={() => {
                        const updatedTeamMembers = [...teamMembers];
                        updatedTeamMembers.splice(rowIndex, 1);
                        setTeamMembers(updatedTeamMembers);
                    }}
                    isIcon
                >
                    <DeleteIcon width="24px" height="24px" />
                </ActionButton>
            </div>
        </div>
    );
}

/**
 * Internal component for a single name/email row in the edit table.
 */
function TeamMemberRow(props: TeamMemberRowProps) {
    const { teamMembers } = props;
    const screenSize = useScreenSizeBreakpoints();
    const theme = useTheme();

    return isSmallerThanOrEqual(screenSize, ScreenSize.SMALL) ? (
        <div
            css={CSS_GROUP_VERTICAL_FIELDS}
            style={{ backgroundColor: theme.colors.background.light_pink }}
        >
            <Row>
                <NameTextField {...props} />
            </Row>
            <Row>
                <EmailTextField {...props} />
                {teamMembers.length > 1 && <DeleteTeamMemberButton {...props} />}
            </Row>
        </div>
    ) : (
        <Row>
            <RowItem>
                <NameTextField {...props} />
            </RowItem>
            <RowItem>
                <EmailTextField {...props} />
            </RowItem>
            {teamMembers.length > 1 && <DeleteTeamMemberButton {...props} />}
        </Row>
    );
}
export default TeamMemberRow;
