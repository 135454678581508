/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

const CSS_TEAM_MEMBER_BLOCK = css({
    borderRadius: '16px',
    maxWidth: '190px',
    padding: '20px',
});

const CSS_MEMBER_IMAGE_WRAPPER = css({
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '8px',
});

const CSS_MEMBER_IMAGE = css({
    borderRadius: '50%',
    height: '130px',
    width: '130px',
    overflow: 'hidden',
});

const CSS_NAME = css({
    fontSize: '20px',
});

const CSS_YEARS = css({
    fontSize: '16px',
    fontWeight: '400',
});

const CSS_FAVORITE_VITAMIN_C_FRUIT = css({
    fontSize: '16px',
    fontWeight: '400',
    paddingTop: '8px',
});

interface TeamMemberIntroProps {
    name: string;
    imageSrc: string;
    pronouns: string[];
    years: string[];
    vitaminCFruit: string;
}

interface TestSolverIntroProps {
    name: string;
    imageSrc: string;
    isTeam?: boolean;
    years: string[];
    vitaminCFruits: string[];
}

type TeamIntroductionProps = (TeamMemberIntroProps | TestSolverIntroProps) & {
    isTestsolver?: boolean;
};

function TeamIntroduction(props: TeamIntroductionProps) {
    const { name, imageSrc, isTestsolver } = props;
    const pronouns = 'pronouns' in props ? props.pronouns : undefined;
    const vitaminCFruit = 'vitaminCFruit' in props ? props.vitaminCFruit : undefined;
    const vitaminCFruits = 'vitaminCFruits' in props ? props.vitaminCFruits : undefined;
    const years = 'years' in props ? props.years : undefined;
    const isTeam = 'isTeam' in props ? props.isTeam : undefined;
    const theme = useTheme();

    const vitaminCFruitsList = vitaminCFruit ? [vitaminCFruit] : vitaminCFruits ?? [];
    const vitaminCFruitString = `${vitaminCFruitsList.join(', ')}`;
    return (
        <div
            css={[
                CSS_TEAM_MEMBER_BLOCK,
                { boxShadow: `2px 2px 20px ${theme.colors.neutral.grey_2}` },
            ]}
        >
            <div css={CSS_MEMBER_IMAGE_WRAPPER}>
                <img src={imageSrc} alt={name} css={CSS_MEMBER_IMAGE} />
            </div>
            <div css={CSS_NAME}>
                <b>
                    {name}
                    {pronouns ? ` (${pronouns.join('/')})` : ''}
                </b>
            </div>
            {!isTestsolver && years && <div css={CSS_YEARS}>Years: {years.join(', ')}</div>}
            {isTestsolver && years && (
                <div css={CSS_YEARS}>
                    Testsolver{isTeam ? 's' : ''} ({years.join(', ')})
                </div>
            )}
            <div css={[CSS_FAVORITE_VITAMIN_C_FRUIT, { color: theme.colors.neutral.grey_5 }]}>
                Favorite Vitamin C Fruit{vitaminCFruitsList.length > 1 ? 's' : ''}:{' '}
                {vitaminCFruitString}
            </div>
        </div>
    );
}

export function TeamMemberIntro(props: TeamMemberIntroProps) {
    return <TeamIntroduction {...props} />;
}

export function TestSolverIntro(props: TestSolverIntroProps) {
    return <TeamIntroduction {...props} isTestsolver />;
}
