/** @jsxImportSource @emotion/react */
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { CURRENT_YEAR } from '../../data/puzzlehunt_details';
import { PAGE_NAMES } from '../../data/page_structure';
import RulesAndInfoContent from '../../data/markdown/rules_and_info_content.md';
import { useMarkdown } from '../../hooks/useMarkdown';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';

function RulesAndInfo() {
    const { html } = useMarkdown(RulesAndInfoContent);
    return (
        <PageLayout>
            <Title>{`${PAGE_NAMES.RULES_AND_INFO} ${CURRENT_YEAR}`}</Title>
            {html && (
                <div
                    dangerouslySetInnerHTML={{ __html: html }}
                    css={CSS_IGNORE_TOP_CONTENT_PADDING}
                />
            )}
        </PageLayout>
    );
}

export default RulesAndInfo;
