/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const CSS_ROW = css({
    '&:last-child': {
        marginBottom: '0px',
    },
    display: 'flex',
    gap: '16px',
    marginBottom: '14px',
    /**
     * This is so that we can position elements relative to form row,
     * e.g. the "Forgot Password?" link on the login page.
     */
    position: 'relative',
    width: '100%',
});

interface RowProps {
    children: React.ReactNode;
}

function Row(props: RowProps) {
    const { children } = props;
    return <div css={CSS_ROW}>{children}</div>;
}

export default Row;
