/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PageLayout from '../components/PageLayout/PageLayout';
import Title from '../components/PageLayout/Title';

const CSS_ERROR_PAGE = css({
    textAlign: 'center',
});

interface ErrorPageProps {
    text?: string;
}

function ErrorPage(props: ErrorPageProps) {
    const { text } = props;

    return (
        <PageLayout>
            <Title>Oops!</Title>
            <div css={CSS_ERROR_PAGE}>
                {text?.split('\\n').map((line) => {
                    return <p key={line}>{line}</p>;
                }) ?? 'Sorry, this page is not available.'}
            </div>
        </PageLayout>
    );
}

export default ErrorPage;
