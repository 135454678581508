import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import AboutUsContent from './AboutUsContent';

function AboutUs() {
    return (
        <PageLayout>
            <Title>{PAGE_NAMES.ABOUT_US}</Title>
            <AboutUsContent />
        </PageLayout>
    );
}

export default AboutUs;
