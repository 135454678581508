/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import Row from '../../components/Form/Row';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import Title from '../../components/PageLayout/Title';
import TextField from '../../components/TextField/TextField';
import PageLayout from '../../components/PageLayout/PageLayout';
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import { HELP_EMAIL } from '../../data/puzzlehunt_details';
import { AccountPageType, CSS_ALIGN_RIGHT } from './account_page_utils';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import { css } from '@emotion/react';
import { FunctionsError } from 'firebase/functions';
import Form from '../../components/Form/Form';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';

const CSS_BACK_TO_LOGIN_LINK = css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
});

const CSS_CONTACT_TEXT = css({
    marginTop: '16px',
});

function ForgotPasswordPage() {
    const [username, setUsername] = useState('');
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [hasEmailSent, setHasEmailSent] = useState(false);

    const checkErrors = () => {
        let hasError = false;
        if (!username) {
            setUsernameErrorMessage('Please enter a username.');
            hasError = true;
        }
        return hasError;
    };

    const { callFunction: resetPasswordFn, isRequestInProgress } =
        useFirebaseFunction('resetPassword');

    const resetPassword = async () => {
        if (checkErrors()) {
            return;
        }
        if (!isRequestInProgress) {
            try {
                await resetPasswordFn({
                    username: username,
                });
                setHasEmailSent(true);
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/not-found') {
                    setUsernameErrorMessage((error as FunctionsError).message);
                } else {
                    console.error('Error while resetting password:', error);
                }
            }
        }
    };

    const isButtonDisabled = isRequestInProgress;

    return (
        <PageLayout maxWidth="400px">
            <Title>{PAGE_NAMES.FORGOT_PASSWORD}</Title>
            {!hasEmailSent ? (
                <Form onSubmit={resetPassword} isDisabled={isButtonDisabled}>
                    <Row>
                        <TextField
                            value={username}
                            setValue={(value) => {
                                setUsername(value);
                                setUsernameErrorMessage('');
                            }}
                            label="Team Username"
                            errorMessage={usernameErrorMessage}
                        />
                    </Row>
                    <Row>
                        <ConditionalLink
                            css={CSS_BACK_TO_LOGIN_LINK}
                            link={PAGE_TO_URL[PAGE_NAMES.LOGIN]}
                            showUnderline
                        >
                            Back to login
                        </ConditionalLink>
                        <div css={CSS_ALIGN_RIGHT}>
                            <ActionButton
                                // Hard-coding width so that the width of button stays the same when disabled.
                                width="160px"
                                buttonStateOverride={
                                    isButtonDisabled ? ButtonState.DISABLED : ButtonState.DEFAULT
                                }
                                isFormSubmit={!isButtonDisabled}
                            >
                                {isRequestInProgress
                                    ? 'Requesting'
                                    : AccountPageType.FORGOT_PASSWORD}
                            </ActionButton>
                        </div>
                    </Row>
                    <Row>
                        <div css={CSS_CONTACT_TEXT}>
                            Forgot your username? Please contact us at{' '}
                            <ConditionalLink link={`mailto:${HELP_EMAIL}`} showUnderline>
                                {HELP_EMAIL}
                            </ConditionalLink>{' '}
                            and we'll get back to you as soon as possible.
                        </div>
                    </Row>
                </Form>
            ) : (
                <>
                    <h2>Password Reset Sent</h2>
                    An email has been sent to all emails associated with your username. If you are
                    still having trouble logging into your account, please contact us at{' '}
                    <ConditionalLink link={`mailto:${HELP_EMAIL}`} showUnderline>
                        {HELP_EMAIL}
                    </ConditionalLink>{' '}
                    and we'll get back to you as soon as possible.
                </>
            )}
        </PageLayout>
    );
}

export default ForgotPasswordPage;
