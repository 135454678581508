/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import TextField from '../../components/TextField/TextField';
import { css, useTheme } from '@emotion/react';
import ActionButton, { ButtonState } from '../../components/ActionButton/ActionButton';
import Row from '../../components/Form/Row';
import { FunctionsError } from 'firebase/functions';
import Form from '../../components/Form/Form';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { isAdmin, isPuzzlehuntOver } from '../../utils/flagging';
import { useAuthUser } from '../../hooks/useAuthUser';
import { getGuessResultMessage } from './guess_result_message';
import { PuzzleInfo } from '../../data/puzzles/puzzle_defs';
import { getBoldFontWeight } from '../../utils/text_formatting';
import { useScreenSizeBreakpoints } from '../../hooks/useScreenSizeBreakpoints';

const MAXIMUM_CHARACTER_LIMIT = 65;

const CSS_SUBMIT_BUTTON = css({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: '16px',
});

export type PuzzleDataType = {
    points: number;
    isMeta: boolean;
} & PuzzleInfo;

interface AnswerSubmissionProps {
    puzzleData: PuzzleDataType | undefined;
    currentPuzzleName: string;
    /**
     * Callback that triggers when guess is submitted.
     */
    onSubmit: () => void;
    /**
     * True when the puzzlehunt is active. When the answer submission is official,
     * it counts towards the stats.
     */
    isOfficial: boolean;
}

function AnswerSubmission(props: AnswerSubmissionProps) {
    const { puzzleData, currentPuzzleName, onSubmit, isOfficial } = props;
    const theme = useTheme();
    const [guess, setGuess] = useState('');
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState(theme.colors.text.dark);
    const [answerSubmissionErrorMessage, setAnswerSubmissionErrorMessage] = useState('');
    const authUser = useAuthUser();
    const screenSize = useScreenSizeBreakpoints();

    const cssGuessStatusMessage = css({
        alignItems: 'center',
        display: 'flex',
        fontSize: '18px',
        fontWeight: getBoldFontWeight(screenSize),
    });

    const { callFunction: checkPuzzleAnswerFn, isRequestInProgress } =
        useFirebaseFunction('checkPuzzleAnswer');

    const checkPuzzleAnswer = async () => {
        if (isOfficial && isPuzzlehuntOver()) {
            // This handles the case where someone had an old version of the answer submission
            // open and tries to submit an answer in it after the hunt closes.
            // Refreshing the page will load the wrap-up puzzle page where submissions won't
            // count towards the leaderboard anymore.
            setMessage('Sorry, the hunt has closed. Please refresh the page!');
            setMessageColor(theme.colors.text.dark);
            return;
        }
        if (!isRequestInProgress) {
            try {
                if (!guess) {
                    setMessage('Did you forget to enter a guess?');
                    setMessageColor(theme.colors.text.dark);
                } else if (authUser && puzzleData) {
                    // Post-process answer to ignore case and extra whitespace.
                    const postprocessedGuess = guess.toUpperCase().replaceAll(' ', '');
                    const checkPuzzleAnswerResponse = await checkPuzzleAnswerFn({
                        username: authUser.uid,
                        puzzleName: currentPuzzleName,
                        guess: postprocessedGuess,
                        points: puzzleData.points,
                        isMeta: puzzleData.isMeta,
                        // The admin account should never be counted into the stats.
                        isOfficial: !isAdmin(authUser) && isOfficial,
                    });
                    const isCorrect = checkPuzzleAnswerResponse.data.isCorrect;
                    const partialAnswer = checkPuzzleAnswerResponse.data.partialAnswer ?? '';
                    const wasPreviouslyGuessed =
                        checkPuzzleAnswerResponse.data.wasPreviouslyGuessed;
                    const hasAlreadyBeenSolved =
                        checkPuzzleAnswerResponse.data.hasAlreadyBeenSolved;
                    setMessage(
                        getGuessResultMessage(
                            postprocessedGuess,
                            isCorrect,
                            partialAnswer,
                            wasPreviouslyGuessed,
                            hasAlreadyBeenSolved,
                        ),
                    );
                    if (isCorrect) {
                        setMessageColor(theme.colors.text.success);
                    } else if (partialAnswer) {
                        setMessageColor(theme.colors.text.dark);
                    } else {
                        setMessageColor(theme.colors.text.error);
                    }
                    setGuess('');
                    onSubmit();
                } else {
                    setMessage('Something went wrong, please try again.');
                    setMessageColor(theme.colors.text.error);
                }
            } catch (error) {
                if ((error as FunctionsError).code === 'functions/not-found') {
                    setMessage((error as FunctionsError).message);
                    setMessageColor(theme.colors.text.error);
                }
                console.error('Error while guessing answer:', error);
            }
        }
    };

    const checkAnswerForInvalidCharacters = (guess: string) => {
        if (/[^a-zA-Z\s]/.test(guess)) {
            // Checks for any characters that are neither letters nor spaces.
            setAnswerSubmissionErrorMessage(
                'Please do not include any numbers or special characters in your guess.',
            );
        } else if (guess.length > MAXIMUM_CHARACTER_LIMIT) {
            setAnswerSubmissionErrorMessage(
                `Woah there! The answer won't be that long, please keep it under ${MAXIMUM_CHARACTER_LIMIT} characters.`,
            );
        } else {
            setAnswerSubmissionErrorMessage('');
        }
    };

    const isButtonDisabled = isRequestInProgress || !!answerSubmissionErrorMessage;

    return (
        <Form onSubmit={checkPuzzleAnswer} isDisabled={isButtonDisabled}>
            <Row>
                <TextField
                    label={isOfficial ? 'Enter your guess:' : 'Check your guess spoiler-free:'}
                    value={guess}
                    setValue={(updatedGuess) => {
                        checkAnswerForInvalidCharacters(updatedGuess);
                        setGuess(updatedGuess);
                        setMessageColor(theme.colors.text.dark);
                        setMessage('');
                    }}
                    errorMessage={answerSubmissionErrorMessage}
                    autoFocus
                />
            </Row>
            <Row>
                <div css={CSS_SUBMIT_BUTTON}>
                    <ActionButton
                        // Hard-coding width so that the width of button stays the same when disabled.
                        width="100px"
                        buttonStateOverride={
                            isButtonDisabled ? ButtonState.DISABLED : ButtonState.DEFAULT
                        }
                        isFormSubmit={!isButtonDisabled}
                    >
                        {isRequestInProgress ? 'Guessing' : 'Guess'}
                    </ActionButton>
                    {message && (
                        <div css={[cssGuessStatusMessage, { color: messageColor }]}>{message}</div>
                    )}
                </div>
            </Row>
        </Form>
    );
}

export default AnswerSubmission;
