import { SectionsType, Sections, AllPuzzlesType } from '../puzzle_defs';

import A_PAIR_IS_TWO_NOT_ONE from './puzzle_pdfs/a_pair_is_two_not_one.pdf';
import A_PAIR_IS_TWO_NOT_ONE_SOLUTION from './solution_pdfs/a_pair_is_two_not_one_solution.pdf';
import CLOSE_ENOUGH from './puzzle_pdfs/close_enough.pdf';
import CLOSE_ENOUGH_SOLUTION from './solution_pdfs/close_enough_solution.pdf';
import COMMONALITIES from './puzzle_pdfs/commonalities.pdf';
import COMMONALITIES_SOLUTION from './solution_pdfs/commonalities_solution.pdf';
import CROSS_SECTIONS_AND_OVERLAPS from './puzzle_pdfs/cross_sections_and_overlaps.pdf';
import CROSS_SECTIONS_AND_OVERLAPS_SOLUTION from './solution_pdfs/cross_sections_and_overlaps_solution.pdf';
import MEASURED_MISTAKES from './puzzle_pdfs/measured_mistakes.pdf';
import MEASURED_MISTAKES_SOLUTION from './solution_pdfs/measured_mistakes_solution.pdf';
import RISK_IT_ALL from './puzzle_pdfs/risk_it_all.pdf';
import RISK_IT_ALL_SOLUTION from './solution_pdfs/risk_it_all_solution.pdf';
import SHOEDOKU from './puzzle_pdfs/shoedoku.pdf';
import SHOEDOKU_SOLUTION from './solution_pdfs/shoedoku_solution.pdf';
import WRAP_UP_AND_SOLUTIONS_2021 from './solution_pdfs/wrap_up_and_solutions_2021.pdf';
import THE_BOX_OF_ORNAMENTS_FELL from './puzzle_pdfs/the_box_of_ornaments_fell_and_this_is_what_addison_ended_up_with.pdf';
import THE_BOX_OF_ORNAMENTS_FELL_SOLUTION from './solution_pdfs/the_box_of_ornaments_fell_and_this_is_what_addison_ended_up_with_solution.pdf';
import THE_MALL from './puzzle_pdfs/the_mall.pdf';
import THE_MALL_SOLUTION from './solution_pdfs/the_mall_solution.pdf';
import UNDER_THE_MISTLETOE from './puzzle_pdfs/under_the_mistletoe.pdf';
import UNDER_THE_MISTLETOE_SOLUTION from './solution_pdfs/under_the_mistletoe_solution.pdf';

export const ALL_PUZZLES_2021: AllPuzzlesType = {
    A_PAIR_IS_TWO_NOT_ONE: {
        name: 'A Pair Is Two, Not One',
        link: A_PAIR_IS_TWO_NOT_ONE,
        solutionLink: A_PAIR_IS_TWO_NOT_ONE_SOLUTION,
    },
    CLOSE_ENOUGH: {
        name: 'Close Enough',
        displayName: 'Close Enough — Lucas',
        link: CLOSE_ENOUGH,
        solutionLink: CLOSE_ENOUGH_SOLUTION,
    },
    COMMONALITIES: {
        name: 'Commonalities',
        displayName: 'Commonalities — Benjamin',
        link: COMMONALITIES,
        solutionLink: COMMONALITIES_SOLUTION,
    },
    CROSS_SECTIONS_AND_OVERLAPS: {
        name: 'Cross-sections and Overlaps',
        displayName: 'Cross-sections and Overlaps — Carson',
        link: CROSS_SECTIONS_AND_OVERLAPS,
        solutionLink: CROSS_SECTIONS_AND_OVERLAPS_SOLUTION,
    },
    MEASURED_MISTAKES: {
        name: 'Measured Mistakes',
        displayName: 'Measured Mistakes — Elaine',
        link: MEASURED_MISTAKES,
        solutionLink: MEASURED_MISTAKES_SOLUTION,
    },
    RISK_IT_ALL: {
        name: 'Risk It All',
        displayName: 'Risk It All — Violet',
        link: RISK_IT_ALL,
        solutionLink: RISK_IT_ALL_SOLUTION,
    },
    SHOEDOKU: {
        name: 'Shoe-doku',
        displayName: 'Shoe-doku — Aurora',
        link: SHOEDOKU,
        solutionLink: SHOEDOKU_SOLUTION,
    },
    THE_BOX_OF_ORNAMENTS_FELL: {
        name: 'The Box Of Ornaments Fell And This Is What Addison Ended Up With',
        displayName: 'The Box Of Ornaments Fell And This Is What Addison Ended Up With — Addison',
        link: THE_BOX_OF_ORNAMENTS_FELL,
        solutionLink: THE_BOX_OF_ORNAMENTS_FELL_SOLUTION,
    },
    THE_MALL: {
        name: 'The Mall',
        displayName: 'The Mall — Orly',
        link: THE_MALL,
        solutionLink: THE_MALL_SOLUTION,
    },
    UNDER_THE_MISTLETOE: {
        name: '🎄 under the mistletoe 🎄',
        displayName: '🎄 under the mistletoe 🎄 — Charlotte',
        link: UNDER_THE_MISTLETOE,
        solutionLink: UNDER_THE_MISTLETOE_SOLUTION,
    },
};

const SECTIONS_2021: SectionsType = {
    [Sections.EASY]: {
        name: Sections.EASY,
        points: 50,
        puzzles: [
            ALL_PUZZLES_2021.THE_BOX_OF_ORNAMENTS_FELL,
            ALL_PUZZLES_2021.COMMONALITIES,
            ALL_PUZZLES_2021.THE_MALL,
        ],
    },
    [Sections.MEDIUM]: {
        name: Sections.MEDIUM,
        points: 100,
        puzzles: [
            ALL_PUZZLES_2021.CROSS_SECTIONS_AND_OVERLAPS,
            ALL_PUZZLES_2021.UNDER_THE_MISTLETOE,
            ALL_PUZZLES_2021.RISK_IT_ALL,
        ],
    },
    [Sections.HARD]: {
        name: Sections.HARD,
        points: 150,
        puzzles: [
            ALL_PUZZLES_2021.MEASURED_MISTAKES,
            ALL_PUZZLES_2021.SHOEDOKU,
            ALL_PUZZLES_2021.CLOSE_ENOUGH,
        ],
    },
    [Sections.META]: {
        name: Sections.META,
        points: 200,
        puzzles: [ALL_PUZZLES_2021.A_PAIR_IS_TWO_NOT_ONE],
    },
    [Sections.WRAP_UP]: {
        solutions: WRAP_UP_AND_SOLUTIONS_2021,
        leaderboard:
            'https://docs.google.com/spreadsheets/d/1dzJhSBfFbeuoyK0Dduhx5ubIwwINLqIH7TsdZWMRfcY/edit?usp=sharing',
    },
};

export default SECTIONS_2021;
