import { useEffect, useState } from 'react';
import { SectionsType } from '../data/puzzles/puzzle_defs';
import { CURRENT_YEAR } from '../data/puzzlehunt_details';

/**
 * Import the current year's puzzles from the data/ folder.
 */
export function useCurrentPuzzleSections() {
    const [puzzleSections, setPuzzleSections] = useState<SectionsType>();

    useEffect(() => {
        // Conditionally import the puzzles from the current year.
        async function importDependencies() {
            let puzzlesExport = await import(
                `../data/puzzles/${CURRENT_YEAR}/${CURRENT_YEAR}_puzzles`
            );
            setPuzzleSections(puzzlesExport.default);
        }

        importDependencies();
    }, []);

    return puzzleSections;
}
