import { SectionsType, Sections, AllPuzzlesType } from '../puzzle_defs';

import ANGSTY_EMAILS from './puzzle_pdfs/angsty_emails.pdf';
import ANGSTY_EMAILS_SOLUTION from './solution_pdfs/angsty_emails_solution.pdf';
import CAROLING_CRAZE from './puzzle_pdfs/caroling_craze.pdf';
import CAROLING_CRAZE_SOLUTION from './solution_pdfs/caroling_craze_solution.pdf';
import COMMON_GROUND from './puzzle_pdfs/common_ground.pdf';
import COMMON_GROUND_SOLUTION from './solution_pdfs/common_ground_solution.pdf';
import DANCING_DRAWINGS from './puzzle_pdfs/dancing_drawings.pdf';
import DANCING_DRAWINGS_SOLUTION from './solution_pdfs/dancing_drawings_solution.pdf';
import GREASE_NO from './puzzle_pdfs/grease_no.pdf';
import GREASE_NO_SOLUTION from './solution_pdfs/grease_no_solution.pdf';
import MOVIE_NIGHT from './puzzle_pdfs/movie_night.pdf';
import MOVIE_NIGHT_SOLUTION from './solution_pdfs/movie_night_solution.pdf';
import MUTINY from './puzzle_pdfs/mutiny.pdf';
import MUTINY_SOLUTION from './solution_pdfs/mutiny_solution.pdf';
import NOT_MORSE_CODE from './puzzle_pdfs/not_morse_code.pdf';
import NOT_MORSE_CODE_SOLUTION from './solution_pdfs/not_morse_code_solution.pdf';
import WRAP_UP_AND_SOLUTIONS_2020 from './solution_pdfs/wrap_up_and_solutions_2020.pdf';
import TANGLED_TERMS from './puzzle_pdfs/tangled_terms.pdf';
import TANGLED_TERMS_SOLUTION from './solution_pdfs/tangled_terms_solution.pdf';
import WE_LOVE_TALKING_TURTLES from './puzzle_pdfs/we_love_talking_turtles.pdf';
import WE_LOVE_TALKING_TURTLES_SOLUTION from './solution_pdfs/we_love_talking_turtles_solution.pdf';

export const ALL_PUZZLES_2020: AllPuzzlesType = {
    ANGSTY_EMAILS: {
        name: 'Angsty Emails',
        link: ANGSTY_EMAILS,
        solutionLink: ANGSTY_EMAILS_SOLUTION,
    },
    CAROLING_CRAZE: {
        name: 'Caroling Craze',
        link: CAROLING_CRAZE,
        solutionLink: CAROLING_CRAZE_SOLUTION,
    },
    COMMON_GROUND: {
        name: 'Common Ground',
        link: COMMON_GROUND,
        solutionLink: COMMON_GROUND_SOLUTION,
    },
    DANCING_DRAWINGS: {
        name: 'Dancing Drawings',
        link: DANCING_DRAWINGS,
        solutionLink: DANCING_DRAWINGS_SOLUTION,
    },
    GREASE_NO: {
        name: 'Grease? No...',
        link: GREASE_NO,
        solutionLink: GREASE_NO_SOLUTION,
    },
    MOVIE_NIGHT: {
        name: 'Movie Night',
        link: MOVIE_NIGHT,
        solutionLink: MOVIE_NIGHT_SOLUTION,
    },
    MUTINY: {
        name: 'Mutiny',
        link: MUTINY,
        solutionLink: MUTINY_SOLUTION,
    },
    NOT_MORSE_CODE: {
        name: 'Not Morse Code',
        link: NOT_MORSE_CODE,
        solutionLink: NOT_MORSE_CODE_SOLUTION,
    },
    TANGLED_TERMS: {
        name: 'Tangled Terms',
        link: TANGLED_TERMS,
        solutionLink: TANGLED_TERMS_SOLUTION,
    },
    WE_LOVE_TALKING_TURTLES: {
        name: 'We Love Talking Turtles',
        link: WE_LOVE_TALKING_TURTLES,
        solutionLink: WE_LOVE_TALKING_TURTLES_SOLUTION,
    },
};

const SECTIONS_2020: SectionsType = {
    [Sections.EASY]: {
        name: Sections.EASY,
        points: 50,
        puzzles: [
            ALL_PUZZLES_2020.GREASE_NO,
            ALL_PUZZLES_2020.TANGLED_TERMS,
            ALL_PUZZLES_2020.CAROLING_CRAZE,
        ],
    },
    [Sections.MEDIUM]: {
        name: Sections.MEDIUM,
        points: 100,
        puzzles: [
            ALL_PUZZLES_2020.ANGSTY_EMAILS,
            ALL_PUZZLES_2020.DANCING_DRAWINGS,
            ALL_PUZZLES_2020.MOVIE_NIGHT,
            ALL_PUZZLES_2020.WE_LOVE_TALKING_TURTLES,
        ],
    },
    [Sections.HARD]: {
        name: Sections.HARD,
        points: 150,
        puzzles: [
            ALL_PUZZLES_2020.NOT_MORSE_CODE,
            ALL_PUZZLES_2020.MUTINY,
            ALL_PUZZLES_2020.COMMON_GROUND,
        ],
    },
    [Sections.WRAP_UP]: {
        solutions: WRAP_UP_AND_SOLUTIONS_2020,
        leaderboard:
            'https://docs.google.com/spreadsheets/d/1v9itrF_5c7-JIgE89p1h3qdORFQ1TSBKae5IeyxB-B4/edit?usp=sharing',
    },
};

export default SECTIONS_2020;
