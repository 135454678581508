import { useEffect } from 'react';

const useEscapeContext = (refs: React.RefObject<HTMLDivElement>[], callback: () => void) => {
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const isInsideRefs = refs.find((ref) => {
                return ref.current && ref.current.contains(e.target as Node);
            });
            if (!isInsideRefs) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, refs]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                callback();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [callback]);
};

export default useEscapeContext;
