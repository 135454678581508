import { SectionsType, Sections, AllPuzzlesType } from '../puzzle_defs';

import CANDELABRA from './puzzle_pdfs/candelabra.pdf';
import CANDELABRA_SOLUTION from './solution_pdfs/candelabra_solution.pdf';
import CARD_GAMES from './puzzle_pdfs/card_games.pdf';
import CARD_GAMES_SOLUTION from './solution_pdfs/card_games_solution.pdf';
import DESSERT_DILEMMA from './puzzle_pdfs/dessert_dilemma.pdf';
import DESSERT_DILEMMA_SOLUTION from './solution_pdfs/dessert_dilemma_solution.pdf';
import DIYA_DILEMMAS from './puzzle_pdfs/diya_dilemmas.pdf';
import DIYA_DILEMMAS_SOLUTION from './solution_pdfs/diya_dilemmas_solution.pdf';
import HOT_CHOCOLATE from './puzzle_pdfs/pls_help_me_choose_what_to_get_in_my_hot_chocolate_thx.pdf';
import HOT_CHOCOLATE_SOLUTION from './solution_pdfs/pls_help_me_choose_what_to_get_in_my_hot_chocolate_thx_solution.pdf';
import LINE_UP from './puzzle_pdfs/line_up.pdf';
import LINE_UP_SOLUTION from './solution_pdfs/line_up_solution.pdf';
import WRAP_UP_AND_SOLUTION_2022 from './solution_pdfs/wrap_up_and_solutions_2022.pdf';
import THE_BOOKSTORE from './puzzle_pdfs/the_bookstore.pdf';
import THE_BOOKSTORE_SOLUTION from './solution_pdfs/the_bookstore_solution.pdf';
import THE_TWELVE_DAYS_OF_CHRISTMAS from './puzzle_pdfs/the_twelve_days_of_christmas.pdf';
import THE_TWELVE_DAYS_OF_CHRISTMAS_SOLUTION from './solution_pdfs/the_twelve_days_of_christmas_solution.pdf';
import VITAMIN_SEE from './puzzle_pdfs/vitamin_see.pdf';
import VITAMIN_SEE_SOLUTION from './solution_pdfs/vitamin_see_solution.pdf';
import WINTER_SCULPTURES from './puzzle_pdfs/winter_sculptures.pdf';
import WINTER_SCULPTURES_SOLUTION from './solution_pdfs/winter_sculptures_solution.pdf';

export const ALL_PUZZLES_2022: AllPuzzlesType = {
    CANDELABRA: {
        name: 'Candelabra',
        displayName: 'Candle Booth — Candelabra',
        link: CANDELABRA,
        solutionLink: CANDELABRA_SOLUTION,
    },
    CARD_GAMES: {
        name: 'Card Games',
        displayName: 'Card Booth — Card Games',
        link: CARD_GAMES,
        solutionLink: CARD_GAMES_SOLUTION,
    },
    DESSERT_DILEMMA: {
        name: 'Dessert Dilemma',
        displayName: 'Food Booth — Dessert Dilemma',
        link: DESSERT_DILEMMA,
        solutionLink: DESSERT_DILEMMA_SOLUTION,
    },
    DIYA_DILEMMAS: {
        name: 'Diya Dilemmas',
        displayName: 'Lamp Booth — Diya Dilemmas',
        link: DIYA_DILEMMAS,
        solutionLink: DIYA_DILEMMAS_SOLUTION,
    },
    HOT_CHOCOLATE: {
        name: 'Pls Help Me Choose What To Get In My Hot Chocolate Thx',
        displayName: 'Hot Chocolate Booth — Pls Help Me Choose What To Get In My Hot Chocolate Thx',
        link: HOT_CHOCOLATE,
        solutionLink: HOT_CHOCOLATE_SOLUTION,
    },
    LINE_UP: {
        name: 'Line Up',
        displayName: 'Waiting in Line — Line Up',
        link: LINE_UP,
        solutionLink: LINE_UP_SOLUTION,
    },
    THE_BOOKSTORE: {
        name: 'The Bookstore',
        displayName: 'Book Booth — The Bookstore',
        link: THE_BOOKSTORE,
        solutionLink: THE_BOOKSTORE_SOLUTION,
    },
    THE_TWELVE_DAYS_OF_CHRISTMAS: {
        name: 'The Twelve Days of Christmas',
        displayName: 'Game Booth — The Twelve Days of Christmas',
        link: THE_TWELVE_DAYS_OF_CHRISTMAS,
        solutionLink: THE_TWELVE_DAYS_OF_CHRISTMAS_SOLUTION,
    },
    VITAMIN_SEE: {
        name: 'Vitamin See',
        displayName: 'Cheese Booth — Vitamin See',
        link: VITAMIN_SEE,
        solutionLink: VITAMIN_SEE_SOLUTION,
    },
    WINTER_SCULPTURES: {
        name: 'Winter Sculptures',
        displayName: 'Sculpture Booth — Winter Sculptures',
        link: WINTER_SCULPTURES,
        solutionLink: WINTER_SCULPTURES_SOLUTION,
    },
};

const SECTIONS_2022: SectionsType = {
    [Sections.EASY]: {
        name: Sections.EASY,
        points: 50,
        puzzles: [
            ALL_PUZZLES_2022.WINTER_SCULPTURES,
            ALL_PUZZLES_2022.DIYA_DILEMMAS,
            ALL_PUZZLES_2022.CANDELABRA,
        ],
    },
    [Sections.MEDIUM]: {
        name: Sections.MEDIUM,
        points: 100,
        puzzles: [
            ALL_PUZZLES_2022.LINE_UP,
            ALL_PUZZLES_2022.VITAMIN_SEE,
            ALL_PUZZLES_2022.THE_BOOKSTORE,
            ALL_PUZZLES_2022.DESSERT_DILEMMA,
        ],
    },
    [Sections.HARD]: {
        name: Sections.HARD,
        points: 150,
        puzzles: [
            ALL_PUZZLES_2022.CARD_GAMES,
            ALL_PUZZLES_2022.THE_TWELVE_DAYS_OF_CHRISTMAS,
            ALL_PUZZLES_2022.HOT_CHOCOLATE,
        ],
    },
    [Sections.WRAP_UP]: {
        solutions: WRAP_UP_AND_SOLUTION_2022,
        leaderboard:
            'https://docs.google.com/spreadsheets/d/1V-lFQpYSiwAWqSXQGx4TNgcgWECUey49JTd-cz8r8-4/edit?usp=sharing',
    },
};

export default SECTIONS_2022;
