import { User, onAuthStateChanged } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '..';

export function useAuthUser() {
    const [authUser, setAuthUser] = useState<User>();
    const auth = useContext(AuthContext);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(undefined);
            }
        });
    }, [auth]);

    return authUser;
}
