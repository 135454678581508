interface SolvedMetaWrapperProps {
    children: React.ReactNode;
}

function SolvedMetaWrapper(props: SolvedMetaWrapperProps) {
    const { children } = props;
    return <>👽🚀⭐ {children} ⭐🚀👽</>;
}

export default SolvedMetaWrapper;
