/** @jsxImportSource @emotion/react */
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import { ABOUT_US_SECTION } from './about_us_page_defs';
import MeetTheTeamContent from './MeetTheTeamContent';
import { useMarkdown } from '../../hooks/useMarkdown';
import AboutHolidayHooplaContent from '../../data/markdown/about_holiday_hoopla_content.md';
import QuestionsOrFeedbackContent from '../../data/markdown/questions_or_feedback_content.md';

function AboutUsContent() {
    const { html: aboutUsHtml } = useMarkdown(AboutHolidayHooplaContent);
    const { html: questionsOrFeedbackHtml } = useMarkdown(QuestionsOrFeedbackContent);

    return (
        <div css={CSS_IGNORE_TOP_CONTENT_PADDING}>
            <div css={ABOUT_US_SECTION}>
                <h2>About Holiday Hoopla</h2>
                {aboutUsHtml && <div dangerouslySetInnerHTML={{ __html: aboutUsHtml }} />}
            </div>
            <div css={ABOUT_US_SECTION}>
                <h2>Meet the Team</h2>
                <MeetTheTeamContent />
            </div>
            <div css={ABOUT_US_SECTION}>
                <h2>Have questions or feedback?</h2>
                {questionsOrFeedbackHtml && (
                    <div dangerouslySetInnerHTML={{ __html: questionsOrFeedbackHtml }} />
                )}
            </div>
        </div>
    );
}

export default AboutUsContent;
