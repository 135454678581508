import { css } from '@emotion/react';

export const USERNAME_MAX_LENGTH = 30;
export const TEAM_NAME_MAX_LENGTH = 100;
export const PASSWORD_MAX_LENGTH = 50;

const INVALID_CHARACTERS = {
    '%': 'percent sign',
};
export const INVALID_CHARACTER_REGEX = new RegExp(
    '\\' + Object.keys(INVALID_CHARACTERS).join('|\\'),
);
export const convertInvalidCharactersToErrorString = () => {
    return Object.entries(INVALID_CHARACTERS)
        .map(([symbol, description]) => {
            return `${symbol} (${description})`;
        })
        .join(', ');
};

export enum AccountPageType {
    LOGIN = 'Login',
    REGISTER = 'Register',
    FORGOT_PASSWORD = 'Submit request',
}

export const CSS_ALIGN_RIGHT = css({
    alignItems: 'flex-end',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '8px',
});
