/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SectionType, Sections } from '../../data/puzzles/puzzle_defs';
import { convertToPathName } from '../../utils/url';
import ErrorPage from '../ErrorPage';
import { Entries } from '../../utils/typing';
import AnswerSubmission, { PuzzleDataType } from './AnswerSubmission';
import ActionButton from '../../components/ActionButton/ActionButton';
import { ReactComponent as OpenInNewIcon } from '../../assets/icons/open_in_new.svg';
import { css } from '@emotion/react';
import CopyToClipboardButton from './PuzzleActionButtons/CopyToClipboardButton';
import { useCurrentPuzzleSections } from '../../hooks/useCurrentPuzzleSections';
import { isMetaAcccessible, isPuzzlehuntOver } from '../../utils/flagging';
import { useAuthUser } from '../../hooks/useAuthUser';
import { CURRENT_YEAR } from '../../data/puzzlehunt_details';
import AttemptedGuessesTable, { AttemptedGuessesType } from './AttemptedGuessesTable';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import DownloadPuzzleButton from './PuzzleActionButtons/DownloadPuzzleButton';
import { PAGE_NAMES, PAGE_TO_URL } from '../../data/page_structure';
import ErrataTable, { ErrataType } from './ErrataTable';

const CSS_PUZZLE_PAGE_CONTENTS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const CSS_PUZZLE_BUTTONS = css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

const CSS_PUZZLEHUNT_END_TEXT = css({
    margin: '0 0 4px 0',
    fontWeight: 700,
});

function IndividualPuzzlePage() {
    const { puzzleNameInUrl } = useParams();
    const [errata, setErrata] = useState<ErrataType>();
    const [attemptedGuesses, setAttemptedGuesses] = useState<AttemptedGuessesType>();
    const authUser = useAuthUser();

    /**
     * True when the puzzlehunt is active. Being "official" just means that any guesses
     * will count towards the leaderboard and stats.
     */
    const isOfficial = !isPuzzlehuntOver();

    const puzzleSections = useCurrentPuzzleSections();
    const { currentPuzzleName, puzzleData } = useMemo((): {
        currentPuzzleName?: string;
        puzzleData?: PuzzleDataType;
    } => {
        if (!puzzleSections) {
            return {};
        }
        // Get the name and scores of all puzzles.
        let relevantSections = [Sections.EASY, Sections.MEDIUM, Sections.HARD];
        if (Sections.META in puzzleSections && isMetaAcccessible(authUser)) {
            relevantSections = [...relevantSections, Sections.META];
        }
        const allPuzzleData = Object.fromEntries(
            relevantSections.reduce(
                (previousPuzzleData, sectionName) => {
                    const puzzleSection = puzzleSections[sectionName] as SectionType;
                    return [
                        ...previousPuzzleData,
                        ...(puzzleSection.puzzles.map(
                            ({ name: puzzleName, ...puzzleInfoProps }) => {
                                return [
                                    puzzleName,
                                    {
                                        points: puzzleSection.points,
                                        isMeta: sectionName === Sections.META,
                                        ...puzzleInfoProps,
                                    },
                                ];
                            },
                        ) as Entries<{ [name: string]: PuzzleDataType }>[]),
                    ];
                },
                [] as Entries<{ [name: string]: PuzzleDataType }>[],
            ),
        );
        const currentPuzzleName = Object.keys(allPuzzleData).find(
            (puzzleName) => convertToPathName(puzzleName) === `/${puzzleNameInUrl}`,
        );
        return {
            currentPuzzleName,
            puzzleData: currentPuzzleName ? allPuzzleData[currentPuzzleName] : undefined,
        };
    }, [authUser, puzzleNameInUrl, puzzleSections]);

    const {
        callFunction: getAttemptedGuessesFn,
        isRequestInProgress: isGetAttemptedGuessesRequestInProgress,
    } = useFirebaseFunction('getAttemptedGuesses');
    const loadAttemptedGuesses = useCallback(() => {
        if (authUser?.uid && currentPuzzleName && !isGetAttemptedGuessesRequestInProgress) {
            getAttemptedGuessesFn({
                username: authUser.uid,
                puzzleName: currentPuzzleName,
            })
                .then((getAttemptedGuessesResponse) => {
                    setAttemptedGuesses(
                        Object.fromEntries(
                            Object.entries(getAttemptedGuessesResponse.data.attemptedGuesses).sort(
                                (a, b) => {
                                    return b[1].solveTime - a[1].solveTime;
                                },
                            ),
                        ),
                    );
                })
                .catch((error) => {
                    console.error(
                        `Error while loading attempted guesses for puzzle ${currentPuzzleName}:`,
                        error,
                    );
                });
        }
    }, [
        authUser,
        currentPuzzleName,
        getAttemptedGuessesFn,
        isGetAttemptedGuessesRequestInProgress,
    ]);

    const { callFunction: getErrataFn, isRequestInProgress: isGetErrataRequestInProgress } =
        useFirebaseFunction('getErrata');
    const loadErrata = useCallback(() => {
        if (authUser?.uid && currentPuzzleName && !isGetErrataRequestInProgress) {
            getErrataFn({
                puzzleName: currentPuzzleName,
            })
                .then((getErrataResponse) => {
                    setErrata(getErrataResponse.data.errata);
                })
                .catch((error) => {
                    console.error(
                        `Error while loading errata for puzzle ${currentPuzzleName}:`,
                        error,
                    );
                });
        }
    }, [authUser, currentPuzzleName, getErrataFn, isGetErrataRequestInProgress]);

    useEffect(() => {
        // Query for attempted guesses from the database.
        if (attemptedGuesses === undefined) {
            loadAttemptedGuesses();
        }
        if (errata === undefined) {
            loadErrata();
        }
    }, [attemptedGuesses, errata, loadAttemptedGuesses, loadErrata]);

    return currentPuzzleName && puzzleData ? (
        <PageLayout>
            <Title backButtonLink={PAGE_TO_URL[PAGE_NAMES.PUZZLES]}>{currentPuzzleName}</Title>
            <div css={CSS_PUZZLE_PAGE_CONTENTS}>
                {errata && <ErrataTable errata={errata} />}
                {!isOfficial && (
                    <div css={CSS_PUZZLEHUNT_END_TEXT}>
                        The {CURRENT_YEAR} puzzlehunt is over! You may keep guessing, but any
                        subsequent guesses will no longer count towards your score.
                    </div>
                )}
                <div css={CSS_PUZZLE_BUTTONS}>
                    <ActionButton
                        width="200px"
                        link={puzzleData.link}
                        ariaLabel={`Open ${puzzleData.name} puzzle PDF (opens in a new tab)`}
                    >
                        <OpenInNewIcon width="18px" height="18px" />
                        Open Puzzle
                    </ActionButton>
                    {currentPuzzleName && puzzleData.link && (
                        <DownloadPuzzleButton
                            puzzleName={currentPuzzleName}
                            puzzleInternalUrl={puzzleData.link}
                        />
                    )}
                    {'formatFriendlyString' in puzzleData &&
                        puzzleData.formatFriendlyString !== undefined && (
                            <CopyToClipboardButton
                                copyableText={puzzleData?.formatFriendlyString}
                            />
                        )}
                    {'image' in puzzleData && puzzleData.image !== undefined && (
                        <CopyToClipboardButton copyableImagePath={puzzleData.image} />
                    )}
                </div>
                {!!authUser?.uid && (
                    <AnswerSubmission
                        puzzleData={puzzleData}
                        currentPuzzleName={currentPuzzleName}
                        isOfficial={isOfficial}
                        onSubmit={loadAttemptedGuesses}
                    />
                )}
                {attemptedGuesses && Object.keys(attemptedGuesses).length > 0 && (
                    <AttemptedGuessesTable attemptedGuesses={attemptedGuesses} />
                )}
            </div>
        </PageLayout>
    ) : (
        <ErrorPage />
    );
}

export default IndividualPuzzlePage;
