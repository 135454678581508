/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import DropdownMenuItem from '../DropdownMenu/DropdownMenuItem';

export type TableOfContentsType = { text: string; onClick: () => void }[];

interface TableOfContentsProps {
    contents: TableOfContentsType;
    selectedItem: string;
}

function TableOfContents(props: TableOfContentsProps) {
    const { contents, selectedItem } = props;
    const theme = useTheme();

    const cssTableOfContents = css({
        borderRadius: '4px',
        boxShadow: `-2px 2px 8px ${theme.colors.neutral.grey_4}`,
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        marginTop: '6px',
        padding: '12px 0px',
        width: '210px',
    });

    return (
        <div css={cssTableOfContents}>
            {contents.map((contentInfo) => {
                return (
                    <DropdownMenuItem
                        key={contentInfo.text}
                        onClick={contentInfo.onClick}
                        isSelected={selectedItem === contentInfo.text}
                    >
                        {contentInfo.text}
                    </DropdownMenuItem>
                );
            })}
        </div>
    );
}

export default TableOfContents;
