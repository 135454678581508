import PageLayout from '../../../components/PageLayout/PageLayout';
import Title from '../../../components/PageLayout/Title';
import { PAGE_NAMES, PAGE_TO_URL } from '../../../data/page_structure';
import HighlightsContent from './HighlightsContent';

function Highlights() {
    return (
        <PageLayout>
            <Title backButtonLink={PAGE_TO_URL[PAGE_NAMES.ARCHIVE]}>{PAGE_NAMES.HIGHLIGHTS}</Title>
            <HighlightsContent />
        </PageLayout>
    );
}

export default Highlights;
