/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Row from '../Form/Row';
import ActionButton, { ButtonType } from '../ActionButton/ActionButton';
import { MAX_TEAM_SIZE } from '../../data/puzzlehunt_details';
import { TeamMembersType } from './edit_team_requirements';
import { useAuthUser } from '../../hooks/useAuthUser';
import { isAdmin } from '../../utils/flagging';
import TeamMemberRow from './TeamMemberRow';

const CSS_ADD_TEAM_MEMBER_BUTTON = css({
    width: 'fit-content',
});

interface EditTeamProps {
    /**
     * Data to display in the table.
     * Note that textfield-level error messages are included, so the parent would need
     * to populate teamMembers with error messages for it to show up properly.
     */
    teamMembers: TeamMembersType;
    setTeamMembers: (teamMembers: TeamMembersType) => void;
    /**
     * This error message will be displayed on the form-level, so only use it
     * for general errors and not anything specific to a textfield.
     */
    errorMessage: string;
}

/**
 * Form that supports inputting name and emails of team members.
 * Error handling includes making sure all members have names and that
 * there is at least one email address inputted.
 */
function EditTeam(props: EditTeamProps) {
    const { teamMembers, setTeamMembers, errorMessage } = props;
    const theme = useTheme();
    const authUser = useAuthUser();

    const cssErrorMessage = css({
        color: theme.colors.text.error,
    });

    return (
        <>
            {errorMessage && (
                <Row>
                    <div css={cssErrorMessage}>{errorMessage}</div>
                </Row>
            )}
            {Array.from([...Array(teamMembers.length)].keys()).map((rowIndex) => {
                return (
                    <TeamMemberRow
                        key={rowIndex}
                        rowIndex={rowIndex}
                        teamMembers={teamMembers}
                        setTeamMembers={setTeamMembers}
                    />
                );
            })}
            {(isAdmin(authUser) || teamMembers.length < MAX_TEAM_SIZE) && (
                <div css={CSS_ADD_TEAM_MEMBER_BUTTON}>
                    <ActionButton
                        onClick={() => {
                            const updatedTeamMembers = [...teamMembers];
                            updatedTeamMembers.push({ name: '', email: '' });
                            setTeamMembers(updatedTeamMembers);
                        }}
                        buttonType={ButtonType.INVISIBLE_ON_WHITE}
                    >
                        Add Person
                    </ActionButton>
                </div>
            )}
        </>
    );
}

export default EditTeam;
