interface FormProps {
    children: React.ReactNode;
    onSubmit: () => void;
    isDisabled?: boolean;
    className?: string;
}

function Form(props: FormProps) {
    const { onSubmit, children, isDisabled, className } = props;
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                if (!isDisabled) {
                    onSubmit();
                }
            }}
            className={className}
        >
            {children}
        </form>
    );
}

export default Form;
