/** @jsxImportSource @emotion/react */
import { useLocation } from 'react-router-dom';

import PageLayout from '../../../components/PageLayout/PageLayout';
import Title from '../../../components/PageLayout/Title';
import { SectionType, Sections, SectionsType } from '../../../data/puzzles/puzzle_defs';
import {
    ARCHIVED_HUNT_URL_TO_PAGE_NAME,
    PAGE_NAMES,
    PAGE_TO_URL,
} from '../../../data/page_structure';
import ErrorPage from '../../ErrorPage';
import { ARCHIVED_YEARS, CURRENT_YEAR } from '../../../data/puzzlehunt_details';
import { css } from '@emotion/react';
import WrapUpSection from '../../../components/PuzzleDisplay/WrapUpSection';
import PuzzleSections from '../../../components/PuzzleDisplay/PuzzleSections';
import { useEffect, useState } from 'react';

const CSS_PAST_HUNTS = css({
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
});

function ArchivedHunts() {
    const [puzzleSections, setPuzzleSections] = useState<SectionsType>();
    const location = useLocation();
    const pageName =
        location.pathname in ARCHIVED_HUNT_URL_TO_PAGE_NAME
            ? ARCHIVED_HUNT_URL_TO_PAGE_NAME[location.pathname]
            : null;
    const puzzlehuntYear = ARCHIVED_YEARS.find((year) => {
        return pageName?.includes(year);
    });

    useEffect(() => {
        // Conditionally import the puzzles from the year that is relevant.
        async function importDependencies() {
            let puzzlesExport = await import(
                `../../../data/puzzles/${puzzlehuntYear}/${puzzlehuntYear}_puzzles`
            );
            setPuzzleSections(puzzlesExport.default);
        }

        importDependencies();
    }, [puzzlehuntYear]);

    let relevantSections = [Sections.EASY, Sections.MEDIUM, Sections.HARD];
    if (puzzleSections && Sections.META in puzzleSections) {
        relevantSections = [...relevantSections, Sections.META];
    }

    return !!pageName && !!puzzlehuntYear ? (
        <PageLayout>
            <Title backButtonLink={PAGE_TO_URL[PAGE_NAMES.ARCHIVE]}>{pageName}</Title>
            {puzzleSections && (
                <div css={CSS_PAST_HUNTS}>
                    <PuzzleSections
                        sections={relevantSections.map((relevantSection) => {
                            return puzzleSections[relevantSection] as SectionType;
                        })}
                        isArchived
                    />
                    {puzzlehuntYear !== CURRENT_YEAR && puzzleSections[Sections.WRAP_UP] && (
                        <WrapUpSection wrapUpInfo={puzzleSections[Sections.WRAP_UP]} />
                    )}
                </div>
            )}
        </PageLayout>
    ) : (
        <ErrorPage />
    );
}

export default ArchivedHunts;
