/** @jsxImportSource @emotion/react */
import { convertUtcToDateString } from '../../utils/date';
import { css, useTheme } from '@emotion/react';

export type ErrataType = { erratum: string; time: number }[];

interface ErrataTableProps {
    errata: ErrataType;
}

function ErrataTable(props: ErrataTableProps) {
    const { errata } = props;
    const theme = useTheme();

    const cssErrataDisplay = css({
        backgroundColor: theme.colors.background.error,
        border: `1px solid ${theme.colors.text.error}`,
        borderRadius: '8px',
        color: theme.colors.text.dark,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        margin: '0px 12px',
        padding: '8px 12px',
    });

    const cssErrataTitle = css({
        color: theme.colors.text.error,
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'center',
    });

    return (
        <div css={cssErrataDisplay}>
            <div css={cssErrataTitle}>
                <b>Errata</b>
            </div>
            {errata.map(({ time, erratum }) => {
                return (
                    <div>
                        <b>{convertUtcToDateString(time)}:</b> {erratum}
                    </div>
                );
            })}
        </div>
    );
}

export default ErrataTable;
