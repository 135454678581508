export type TeamMembersType = {
    name: string;
    email: string;
    nameErrorMessage?: string;
    emailErrorMessage?: string;
}[];

/**
 * Referenced from https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression.
 */
const VALID_EMAIL_REGEX =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function getIndicesWithInvalidEmails(teamMembers: TeamMembersType) {
    // Checks if any emails are formatted incorrectly.
    return teamMembers.reduce((idsWithMissingNames, currentMember, index) => {
        if (currentMember.email && !VALID_EMAIL_REGEX.test(currentMember.email)) {
            idsWithMissingNames.push(index);
        }
        return idsWithMissingNames;
    }, [] as number[]);
}

function getIndicesWithMissingNames(teamMembers: TeamMembersType) {
    // All team members with emails must also have names.
    return teamMembers.reduce((idsWithMissingNames, currentMember, index) => {
        if (currentMember.email && !currentMember.name) {
            idsWithMissingNames.push(index);
        }
        return idsWithMissingNames;
    }, [] as number[]);
}

export function hasEmailPresent(teamMembers: TeamMembersType) {
    // At least one email must be present.
    return !!teamMembers.find((teamMemberInfo) => {
        return !!teamMemberInfo.email;
    });
}

export function hasDuplicateEmails(teamMembers: TeamMembersType) {
    // All email addresses on the same team must be unique.
    const emails = teamMembers.map(({ email }) => email);
    return new Set(emails).size !== emails.length;
}

export function addErrorMessagesIfInvalid(teamMembers: TeamMembersType): {
    hasError: boolean;
    teamMembers: TeamMembersType;
} {
    let hasError = false;
    const updatedTeamMembers = [...teamMembers];
    const indicesWithMissingNames = getIndicesWithMissingNames(teamMembers);
    if (indicesWithMissingNames.length > 0) {
        indicesWithMissingNames.forEach((index) => {
            updatedTeamMembers[index].nameErrorMessage = 'Please enter a name.';
        });
        hasError = true;
    }
    const indicesWithInvalidEmails = getIndicesWithInvalidEmails(teamMembers);
    if (indicesWithInvalidEmails.length > 0) {
        indicesWithInvalidEmails.forEach((index) => {
            updatedTeamMembers[index].emailErrorMessage = 'Invalid email address.';
        });
        hasError = true;
    }
    return {
        hasError: hasError,
        teamMembers: updatedTeamMembers,
    };
}

export function getTeamMembersNameAndEmail(teamMembers: TeamMembersType) {
    return teamMembers.reduce(
        (accumulativeTeamMembers, { name, email }) => {
            if (name.length > 0) {
                accumulativeTeamMembers.push({ name, email });
            }
            return accumulativeTeamMembers;
        },
        [] as { name: string; email: string }[],
    );
}
