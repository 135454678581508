/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { convertUtcToDateString } from '../../../utils/date';
import Table from '../../Table/Table';
import SolvedMetaWrapper from '../SolvedMetaWrapper';
import { useMemo } from 'react';
import { Sections } from '../../../data/puzzles/puzzle_defs';
import { useCurrentPuzzleSections } from '../../../hooks/useCurrentPuzzleSections';

export type SolvedPuzzleDataType = {
    [puzzleName: string]: { solveTime: number; answer: string };
};

interface SolvedPuzzlesTableProps {
    solvedPuzzleData: SolvedPuzzleDataType;
}

function SolvedPuzzlesTable(props: SolvedPuzzlesTableProps) {
    const { solvedPuzzleData } = props;
    const theme = useTheme();

    const puzzleSections = useCurrentPuzzleSections();
    const metaNames = useMemo(() => {
        if (puzzleSections && Sections.META in puzzleSections && puzzleSections?.[Sections.META]) {
            return puzzleSections[Sections.META].puzzles.map((puzzleInfo) => puzzleInfo.name);
        }
        return [];
    }, [puzzleSections]);

    const cssAnswerText = css({
        color: theme.colors.text.success,
        fontWeight: 500,
    });

    return (
        <>
            <Table
                columns={[
                    { columnName: 'Puzzle', widthPercentage: '60%' },
                    { columnName: 'Answer', widthPercentage: '20%' },
                    { columnName: 'Solve Time', widthPercentage: '20%' },
                ]}
                data={Object.entries(solvedPuzzleData).map(
                    ([puzzleName, { solveTime, answer }]) => {
                        return {
                            Puzzle: metaNames?.includes(puzzleName) ? (
                                <SolvedMetaWrapper>{puzzleName}</SolvedMetaWrapper>
                            ) : (
                                puzzleName
                            ),
                            Answer: <div css={cssAnswerText}>{answer}</div>,
                            'Solve Time': convertUtcToDateString(solveTime),
                        };
                    },
                )}
            />
        </>
    );
}

export default SolvedPuzzlesTable;
