import { User } from 'firebase/auth';
import {
    ADMIN_USERNAME,
    PuzzlehuntState,
    RELEASE_TIMES,
    TESTSOLVE_USERNAME,
} from '../data/puzzlehunt_details';
import { ACTIVE_PAGES } from '../data/page_structure';

function getPuzzlehuntState() {
    const currentTime = Date.now();
    if (RELEASE_TIMES.END <= currentTime) {
        return PuzzlehuntState.WRAP_UP;
    } else if (RELEASE_TIMES.START <= currentTime) {
        return PuzzlehuntState.ACTIVE;
    } else if (RELEASE_TIMES.REGISTRATION_START <= currentTime) {
        return PuzzlehuntState.REGISTRATION_OPEN;
    }
    return PuzzlehuntState.INACTIVE;
}

export function isAdmin(user: User | undefined) {
    return user?.uid === ADMIN_USERNAME;
}

function isTestsolver(user: User | undefined) {
    return user?.uid.includes(TESTSOLVE_USERNAME);
}

function isPageActive(pageName: string) {
    return ACTIVE_PAGES[pageName].includes(getPuzzlehuntState());
}

/**
 * Returns whether a page is accessible to the current user.
 * Pages are always accessible to admins, for website development and testing purposes.
 */
export function isPageAccessible(
    user: User | undefined,
    pageName: string,
    adminOnly: boolean,
    activePageOnly: boolean,
) {
    if (adminOnly) {
        return isAdmin(user);
    }
    if (activePageOnly) {
        return isAdmin(user) || isPageActive(pageName);
    }
    return isAdmin(user) || isTestsolver(user) || isPageActive(pageName);
}

export function isPuzzlehuntOver() {
    return getPuzzlehuntState() === PuzzlehuntState.WRAP_UP;
}

/**
 * Checks whether the meta is released. This allows the meta to be released
 * after the main puzzles after a set amount of time.
 */
export function isMetaAcccessible(user: User | undefined) {
    return isAdmin(user) || isTestsolver(user) || RELEASE_TIMES.META <= Date.now();
}
