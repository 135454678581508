/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import Row from '../../../components/Form/Row';
import { css } from '@emotion/react';
import { useFirebaseFunction } from '../../../hooks/useFirebaseFunction';
import { useAuthUser } from '../../../hooks/useAuthUser';
import SolvedPuzzlesTable, {
    SolvedPuzzleDataType,
} from '../../../components/PuzzleDisplay/SolvedPuzzlesTable/SolvedPuzzlesTable';

const CSS_TEAM_INFO_ROW = css({
    display: 'flex',
    flexDirection: 'column',
});

const CSS_TEAM_INFO_SECTION = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
});

const CSS_PUZZLE_PROGRESS = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

function TeamInfo() {
    const [username, setUsername] = useState<string>();
    const [teamName, setTeamName] = useState<string>();
    const [teamMemberNames, setTeamMemberNames] = useState<string[]>();
    const [solvedPuzzleData, setSolvedPuzzleData] = useState<SolvedPuzzleDataType | undefined>();
    const [score, setScore] = useState(0);
    const authUser = useAuthUser();

    const {
        callFunction: getTeamMembersFn,
        isRequestInProgress: isGetTeamMembersRequestInProgress,
    } = useFirebaseFunction('getTeamMembers');
    const {
        callFunction: getSolvedPuzzlesFn,
        isRequestInProgress: isGetSolvedPuzzlesRequestInProgress,
    } = useFirebaseFunction('getSolvedPuzzles');

    const loadTeamMembers = useCallback(
        (username: string) => {
            if (!isGetTeamMembersRequestInProgress && teamMemberNames === undefined) {
                getTeamMembersFn({ username: username })
                    .then((getTeamMembersResponse) => {
                        const teamMembersData = getTeamMembersResponse.data.teamMembers;
                        setTeamMemberNames(
                            teamMembersData.map((teamMemberData) => {
                                return teamMemberData.name;
                            }),
                        );
                    })
                    .catch((error) => {
                        console.error('Error while fetching team member data:', error);
                    });
            }
        },
        [getTeamMembersFn, isGetTeamMembersRequestInProgress, teamMemberNames],
    );

    const loadSolvedAnswers = useCallback(
        (username: string) => {
            if (!isGetSolvedPuzzlesRequestInProgress && solvedPuzzleData === undefined) {
                getSolvedPuzzlesFn({
                    username: username,
                })
                    .then((getSolvedPuzzlesResponse) => {
                        const solvedPuzzles = getSolvedPuzzlesResponse.data.solvedPuzzles;
                        const sortedSolvedPuzzles = Object.fromEntries(
                            Object.entries(solvedPuzzles).sort(
                                (a, b) => b[1].solveTime - a[1].solveTime,
                            ),
                        );
                        setSolvedPuzzleData(sortedSolvedPuzzles);
                        setScore(getSolvedPuzzlesResponse.data.score);
                    })
                    .catch((error) => {
                        console.error(
                            `Error while loading solved puzzles for team ${username}: ${error}`,
                        );
                    });
            }
        },
        [getSolvedPuzzlesFn, isGetSolvedPuzzlesRequestInProgress, solvedPuzzleData],
    );

    useEffect(() => {
        if (authUser?.uid) {
            setUsername(authUser.uid);
            setTeamName(authUser.displayName ?? authUser.uid);
            loadTeamMembers(authUser.uid);
            loadSolvedAnswers(authUser.uid);
        } else {
            setUsername(undefined);
            setTeamName(undefined);
        }
    }, [authUser, loadSolvedAnswers, loadTeamMembers]);

    return (
        <>
            <Row>
                <div css={CSS_TEAM_INFO_ROW}>
                    <h3>Username</h3>
                    {username}
                </div>
            </Row>
            <Row>
                <div css={CSS_TEAM_INFO_ROW}>
                    <h3>Team name</h3>
                    {teamName}
                </div>
            </Row>
            <Row>
                <div css={CSS_TEAM_INFO_ROW}>
                    <h3>Team members</h3>
                    {teamMemberNames && teamMemberNames.length > 0
                        ? teamMemberNames.join(', ')
                        : 'Loading...'}
                </div>
            </Row>
            <Row>
                <div css={CSS_TEAM_INFO_ROW}>
                    <h3>Statistics</h3>
                    <div css={CSS_TEAM_INFO_SECTION}>
                        <div>Total score: {score}</div>
                    </div>
                </div>
            </Row>
            {!!solvedPuzzleData && (
                <div css={CSS_PUZZLE_PROGRESS}>
                    <div>
                        {Object.keys(solvedPuzzleData).length > 0 && (
                            <SolvedPuzzlesTable solvedPuzzleData={solvedPuzzleData} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default TeamInfo;
