import photoOrange from '../assets/images/orange_square.png';
import photoKiwi from '../assets/images/kiwi_square.png';
import photoStrawberry from '../assets/images/strawberry_square.png';
import photoMango from '../assets/images/mango_square.png';
import photoCheatahs from '../assets/images/strawberries_square.jpg';
import photoEllie from '../assets/images/oranges_square.jpg';
import photoCowpeabara from '../assets/images/strawberries_and_oranges_square.jpg';
import placeholderPhoto from '../assets/images/orange_slice.svg';

type TeamMemberInfo = {
    name: string;
    imageSrc: string;
    pronouns: string[];
    years: string[];
    vitaminCFruit: string;
};

export const FOUNDERS: TeamMemberInfo[] = [
    {
        name: 'Anvi',
        imageSrc: photoOrange,
        pronouns: ['she', 'her'],
        years: ['2020 - now'],
        vitaminCFruit: 'Orange',
    },
    {
        name: 'Sienna',
        imageSrc: photoKiwi,
        pronouns: ['she', 'her'],
        years: ['2020 - now'],
        vitaminCFruit: 'Kiwi',
    },
];

export const PUZZLE_WRITERS: TeamMemberInfo[] = [
    {
        name: 'Ananya',
        imageSrc: photoStrawberry,
        pronouns: ['she', 'her'],
        years: ['2024', '2023'],
        vitaminCFruit: 'Strawberry',
    },
    {
        name: 'Cindy',
        imageSrc: photoMango,
        pronouns: ['she', 'her'],
        years: ['2024', '2023'],
        vitaminCFruit: 'Mango',
    },
    {
        name: 'Arpit',
        imageSrc: photoMango,
        pronouns: ['he', 'him'],
        years: ['2024'],
        vitaminCFruit: 'Mango',
    },
    {
        name: 'Ian',
        imageSrc: photoStrawberry,
        pronouns: ['he', 'him'],
        years: ['2024'],
        vitaminCFruit: 'Strawberry',
    },
];

// Ordered from most recent year to least recent year.
export const TEST_SOLVERS: {
    name: string;
    imageSrc: string;
    isTeam?: boolean;
    years: string[];
    vitaminCFruits: string[];
}[] = [
    {
        name: 'Team Cheatahs',
        imageSrc: photoCheatahs,
        isTeam: true,
        years: ['2023', '2022'],
        vitaminCFruits: ['Strawberry'],
    },
    {
        name: 'Ellie Feng',
        imageSrc: photoEllie,
        isTeam: false,
        years: ['2023', '2021'],
        vitaminCFruits: ['Orange'],
    },
    {
        name: 'Erin Price',
        imageSrc: placeholderPhoto,
        isTeam: false,
        years: ['2023'],
        vitaminCFruits: ['N/A'],
    },
    {
        name: 'Matthew Siff',
        imageSrc: placeholderPhoto,
        isTeam: false,
        years: ['2021'],
        vitaminCFruits: ['N/A'],
    },
    {
        name: 'Team Cowpeabara',
        imageSrc: photoCowpeabara,
        isTeam: true,
        years: ['2020'],
        vitaminCFruits: ['Orange', 'Strawberry'],
    },
];
