export interface PuzzleStats {
    guesses: number;
    solves: number;
}

export interface AllPuzzleStats {
    [puzzleName: string]: PuzzleStats;
}

export function getPuzzleStatsString(puzzleStat: PuzzleStats) {
    const guesses = puzzleStat.guesses ?? 0;
    const solves = puzzleStat.solves ?? 0;
    return `${solves} solve${solves === 1 ? '' : 's'} / ${guesses} attempt${
        guesses === 1 ? '' : 's'
    }`;
}
