import { useCallback } from 'react';
import ActionButton, { ButtonType } from '../../../components/ActionButton/ActionButton';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';

interface DownloadPuzzleButtonProps {
    puzzleName: string;
    puzzleInternalUrl: string;
}

function DownloadPuzzleButton(props: DownloadPuzzleButtonProps) {
    const { puzzleName, puzzleInternalUrl } = props;

    const downloadPuzzle = useCallback(() => {
        let alink = document.createElement('a');
        alink.href = puzzleInternalUrl;
        alink.download = puzzleName;
        alink.click();
    }, [puzzleName, puzzleInternalUrl]);

    return (
        <ActionButton width="200px" buttonType={ButtonType.SECONDARY} onClick={downloadPuzzle}>
            <DownloadIcon width="18px" height="18px" />
            Download Puzzle
        </ActionButton>
    );
}

export default DownloadPuzzleButton;
