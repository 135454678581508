/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const CSS_IGNORE_TOP_CONTENT_PADDING = css({
    'p:first-of-type': {
        marginTop: '0px',
    },
    'h2:first-of-type': {
        marginTop: '0px',
    },
    'h3:first-of-type': {
        marginTop: '0px',
    },
});
