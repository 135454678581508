import { useCallback, useEffect, useState } from 'react';
import ActionButton, { ButtonType } from '../../../components/ActionButton/ActionButton';
import { ReactComponent as CopyIcon } from '../../../assets/icons/content_copy.svg';
import ConditionalTooltip from '../../../components/Tooltip/ConditionalTooltip';

interface CopyTextToClipboardButtonProps {
    copyableText?: string;
}

interface CopyImageToClipboardButtonProps {
    copyableImagePath?: string;
}

type CopyToClipboardButtonProps = CopyTextToClipboardButtonProps | CopyImageToClipboardButtonProps;

function CopyToClipboardButton(props: CopyToClipboardButtonProps) {
    const [imageBlob, setImageBlob] = useState<Blob>();
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const copyableText = 'copyableText' in props ? props.copyableText : undefined;
    const copyableImagePath = 'copyableImagePath' in props ? props.copyableImagePath : undefined;

    useEffect(() => {
        if (copyableImagePath) {
            // If there's a copyable image, convert the image into a blob.
            fetch(copyableImagePath).then((image) => {
                image.blob().then((imageBlob) => {
                    setImageBlob(imageBlob);
                });
            });
        }
    }, [copyableImagePath]);

    const copyContent = useCallback(() => {
        if (copyableText) {
            navigator.clipboard.writeText(copyableText);
        } else if (imageBlob) {
            navigator.clipboard.write([new ClipboardItem({ [imageBlob.type]: imageBlob })]);
        }
        setIsTooltipVisible(true);
        setTimeout(() => {
            setIsTooltipVisible(false);
        }, 1000);
    }, [copyableText, imageBlob]);

    return (
        <ConditionalTooltip tooltip={'Copied!'} showTooltip={isTooltipVisible}>
            <ActionButton width="200px" buttonType={ButtonType.SECONDARY} onClick={copyContent}>
                <CopyIcon width="18px" height="18px" />
                Copy to Clipboard
            </ActionButton>
        </ConditionalTooltip>
    );
}

export default CopyToClipboardButton;
