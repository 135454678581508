export function getGuessResultMessage(
    guess: string,
    isCorrect: boolean,
    partialAnswer: string,
    wasPreviouslyGuessed: boolean,
    hasAlreadyBeenSolved: boolean,
) {
    if (hasAlreadyBeenSolved) {
        return "You've already solved this puzzle!";
    }
    const wasPreviouslyGuessedString = wasPreviouslyGuessed
        ? ', but you already guessed this before'
        : '';

    if (isCorrect) {
        return `${guess} is correct${wasPreviouslyGuessedString}!`;
    } else if (partialAnswer) {
        return partialAnswer;
    } else {
        return `Incorrect${wasPreviouslyGuessedString}!`;
    }
}
